import { Component } from '@angular/core';
import { LiveService } from '../../services/live.service';
import { SideNavService } from '../../services/side-nav.service';
import { AudioService } from '../../services/audio.service';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
})
export class NotificationBarComponent {
  constructor(
    public sideNavService: SideNavService,
    public liveService: LiveService,
    public audioService: AudioService,
  ) {}

  acceptDenyUser(accept: boolean, participantId: string) {
    if (accept) {
      this.liveService.inviteParticipant(participantId);
    } else {
      this.liveService.denyParticipant(participantId);
    }

    const currentLiseNotification = this.liveService.participantsToValidateNotification$.getValue();
    const existingIndex = currentLiseNotification.findIndex((cln) => cln.email == participantId);
    if (existingIndex != -1) {
      currentLiseNotification.splice(existingIndex, 1);
      this.liveService.participantsToValidateNotification$.next(currentLiseNotification);
    }
  }
}
