import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserManagement } from '../../models/User';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent {
  public selectedUsers: UserManagement[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; users: UserManagement[] },
    public utils: UtilsService,
    public dialogRef: MatDialogRef<ManageUsersComponent>,
  ) {}

  /**
   * Add a new user in a selected list
   * @param selectedEquipment
   */
  userSelected(selectedUser: any) {
    const userValue: UserManagement = selectedUser[0].value;
    if (selectedUser[0].selected) {
      this.selectedUsers.push(userValue);
    } else {
      this.selectedUsers = this.selectedUsers.filter((u: UserManagement) => u.email !== userValue.email);
    }
  }

  /**
   * Check if a user is already selected
   * @param equipment
   * @returns
   */
  isSelected(user: UserManagement): boolean {
    return this.selectedUsers.findIndex((u) => u.email == user.email) >= 0;
  }

  removeUsers() {
    this.dialogRef.close({ usersToRemove: this.selectedUsers });
  }

  /**
   * Cancel operation and close dialog
   */
  cancelButton() {
    this.dialogRef.close();
  }
}
