import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class NotEquipmentGuard {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (await this.userService.isMedinboxEquipment()) {
      this.router.navigate(['medinbox', 'home']);
      return false;
    } else {
      return true;
    }
  }
}
