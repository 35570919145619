import {
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, lastValueFrom } from 'rxjs';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';

/**
 * The HTTP  interceptor  outing request to attach authorization token to it and catch errors from
 * HTTP call to display a message to the user
 */
@Injectable({
  providedIn: 'root',
})
export class ApiHttpInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService,
    private authService: AuthService,
  ) {}

  // intercept request and add Authorization token
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return from(this.handleAccessToken(req, next));
  }

  private async handleAccessToken(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    if ((await this.userService.isAuthenticated()) && req.url.indexOf('voxeet') == -1 && req.url.indexOf('tokbox') == -1) {
      const token = await this.authService.getIdToken();
      req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }
    return await lastValueFrom(next.handle(req));
  }
}
