import { Injectable } from '@angular/core';
import { CookieStorage } from '@aws-amplify/core';
import { Amplify } from 'aws-amplify';
import {
  getCurrentUser,
  fetchAuthSession,
  fetchUserAttributes,
  confirmSignUp,
  signIn,
  signOut,
  resendSignUpCode,
  resetPassword,
  updatePassword,
  confirmResetPassword,
  SignInInput,
  AuthError,
} from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.cognito.userPoolId,
          userPoolClientId: environment.cognito.userPoolWebClientId,
        },
      },
    });

    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      const session = await fetchAuthSession();
      if (session.userSub) {
        const currentUser = await getCurrentUser();
        if (currentUser?.signInDetails?.loginId) {
          return true;
        }
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getUserAttributes(): Promise<any> {
    return await fetchUserAttributes();
  }

  async emailVerified(): Promise<boolean> {
    const attributes = await fetchUserAttributes();
    return attributes.email_verified == 'true';
  }

  async getUserApplicationRoles(): Promise<string[]> {
    const session = await fetchAuthSession();
    return (session.tokens?.idToken?.payload['cognito:groups'] as string[]) || [];
  }

  async confirmSignUp(email: string, code: string): Promise<boolean> {
    const confirm = await confirmSignUp({ username: email, confirmationCode: code });
    return confirm.isSignUpComplete;
  }

  async signingIn({ username, password }: SignInInput): Promise<string | AuthError | null> {
    try {
      const { nextStep } = await signIn({ username, password });
      return nextStep.signInStep;
    } catch (error) {
      if (error instanceof AuthError && error.name == 'UserAlreadyAuthenticatedException') {
        return error;
      }
      console.log(error);
      return null;
    }
  }

  async resendConfirmCode(email: string): Promise<void> {
    await resendSignUpCode({ username: email });
  }

  async changePassword(oldPassword: string, newPwd: string): Promise<void> {
    await updatePassword({ oldPassword: oldPassword, newPassword: newPwd });
  }

  async signOut() {
    await signOut();
  }

  async getIdToken(): Promise<string> {
    try {
      const currentSession = await fetchAuthSession();
      return currentSession.tokens?.idToken?.toString() ?? 'no token found';
    } catch (error: any) {
      if (error.code === 'NotAuthorizedException') {
        // Handle expired session and refresh token
        const refreshedSession = await fetchAuthSession({ forceRefresh: true });
        return refreshedSession.tokens?.idToken?.toString() ?? 'no token found';
      } else {
        console.error('Error fetching ID token:', error);
        return 'no token found';
      }
    }
  }

  async resetPassword(email: string): Promise<any> {
    const sent = await resetPassword({ username: email });
    return sent.nextStep;
  }

  async submitResetPassword(email: string, code: string, newPassword: string): Promise<void> {
    await confirmResetPassword({ username: email, confirmationCode: code, newPassword: newPassword });
  }
}
