export enum SideNavComponentEnum {
  Menu = 1,
  ParticipantsList = 2,
  Chat = 3,
  Layout = 4,
  SoundManagement = 5,
  UserDetails = 6,
  RightsManagement = 7,
  Source = 8,
  Settings = 9,
  PrivateDiscussion = 10,
  Bookmarks = 11,
}
