import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, of } from 'rxjs';
import { ChatMessage } from '../models/ChatMessage';
import { WebSocketMessage } from '../models/WebSocketMessage';
import { LiveService } from './live.service';
import { UserService } from './user.service';
import { WebSocketClientService } from './web-socket-client.service';
import { WebSocketActionEnum } from '../models/enums/WebsocketActions';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public chatMessages$: BehaviorSubject<ChatMessage[]> = new BehaviorSubject<ChatMessage[]>([]);
  public chatWindowOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private webSocketClient: WebSocketClientService,
    private liveService: LiveService,
    private userService: UserService,
  ) {
    this.webSocketClient.chatMessage$
      .pipe(
        filter((cm) => cm != null),
        map((cm) => {
          if (cm?.audience == 'LIVE') {
            if (cm.message.action == WebSocketActionEnum.CHAT) {
              const messages = this.chatMessages$.getValue();
              const date = new Date(cm.timeStamp ?? new Date().getTime());
              const message: ChatMessage = {
                email: cm?.message.params.email,
                text: cm?.message.params.text,
                timeStamp: cm.timeStamp ?? 0,
                formatedTime: `${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`,
                isRead: this.chatWindowOpen$.getValue(),
              };
              messages.push(message);
              messages.sort((a, b) => a.timeStamp - b.timeStamp);
              this.chatMessages$.next(messages);
            } else if (cm.message.action == WebSocketActionEnum.CHAT_INIT) {
              const messages = cm.message.params as ChatMessage[];
              if (messages.length) {
                messages.forEach((m) => {
                  const date = new Date(m.timeStamp);
                  (m.formatedTime = `${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`), (m.isRead = true);
                });
                this.chatMessages$.next(messages);
              }
            }
          }
        }),
      )
      .subscribe();
  }

  public sendMessage(text: string) {
    if (this.liveService.currentrights$.getValue().canWriteChat) {
      const currentUser = this.userService.user$.getValue();
      this.webSocketClient.SendChatMessage({
        audience: 'LIVE',
        destinationId: this.liveService.currentLive$.getValue()?.reference,
        sourceId: this.userService.getCurrentUserId(),
        message: {
          action: WebSocketActionEnum.CHAT,
          params: {
            text: text,
            email: currentUser?.email,
          } as ChatMessage,
        },
      } as WebSocketMessage);
    }
  }

  public getNumberOfUnreadMessaage(): Observable<number> {
    if (this.liveService.currentrights$.getValue().canViewChat) {
      return this.chatMessages$.pipe(
        map((cm) => {
          return cm.filter((m) => !m.isRead).length ?? 0;
        }),
      );
    }
    return of(0);
  }

  public async markAllMessageAsRead(): Promise<void> {
    const messages = this.chatMessages$.getValue();
    messages.forEach((m) => (m.isRead = true));
    this.chatMessages$.next(messages);
  }
}
