import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { sendUserPrompt } from '../models/SendUserPrompt';

@Injectable({
  providedIn: 'root',
})
export class ChatGptService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Send user question to OpenAI
   * @param videoGuid
   * @param userPrompt
   * @returns
   */
  public sendUserPrompt(sendUserPrompt: sendUserPrompt): Observable<string> {
    return this.httpClient.post(`${environment.apiUrl}/Ai/GenerateResponseOpenAI`, sendUserPrompt, { responseType: 'text' }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        return throwError(() => error);
      }),
    );
  }

  public startAiPipelineForVideo(videoMediaFileGuid: string) {
    return this.httpClient.post<any>(`${environment.apiUrl}/Ai/StartAiPipelineForVideo?videoMediaFileGuid=${videoMediaFileGuid}`, null);
  }

  public resetAiStatus(videoMediaFileGuid: string) {
    return this.httpClient.post<any>(`${environment.apiUrl}/Ai/ResetAiStatus?videoMediaFileGuid=${videoMediaFileGuid}`, null);
  }
}
