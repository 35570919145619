<div class="relative inline-flex w-full">
  <input
    type="text"
    matInput
    [ngClass]="inputClass()"
    [matAutocomplete]="auto"
    #autoCompleteInput
    #trigger="matAutocompleteTrigger"
    (change)="handleNullValue($event)"
    [formControl]="countryControl"
    [value]="selectedCountry ? selectedCountry.name : ''"
  />
  @if (selectedCountry) {
    <button
      id="clearButton"
      [autofocus]="false"
      tabindex="-1"
      class="autocomplete-clear"
      aria-label="Clear"
      aria-hidden="false"
      (click)="clearAutocomplete($event)"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  }
</div>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="setSelected($event.option.value)" [displayWith]="displayFn">
  @for (country of filteredCountries | async; track country) {
    <mat-option [value]="country">
      {{ country.name }}
    </mat-option>
  }
</mat-autocomplete>
