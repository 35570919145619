@if (
  {
    user: this.userService.user$ | async
  };
  as values
) {
  @if (!menu) {
    <span class="profile-no-menu">
      <div class="profile-text">{{ values.user?.firstName?.charAt(0) }}{{ values.user?.lastName?.charAt(0) }}</div>
    </span>
  } @else {
    <div class="profile-position">
      <a [matMenuTriggerFor]="lineMenu" class="profile">
        <div class="profile-text">{{ values.user?.firstName?.charAt(0) }}{{ values.user?.lastName?.charAt(0) }}</div>
      </a>
      <mat-menu class="profile-menu" #lineMenu="matMenu" yPosition="below" xPosition="before">
        @if (equipment) {
          <button class="line-menu log-out" mat-menu-item (click)="logOutEquipment()">
            <mat-icon svgIcon="log-out-icon"></mat-icon>&nbsp;{{ 'GLOBAL.EXIT' | translate }}
          </button>
        } @else {
          <button class="line-menu" mat-menu-item (click)="goToProfile()">
            <mat-icon svgIcon="profile"></mat-icon>&nbsp;{{ 'GLOBAL.PROFILE' | translate }}
          </button>

          @if (isAdmin()) {
            <button class="line-menu" mat-menu-item routerLink="/organizations/my-organizations">
              <mat-icon svgIcon="my-organizations"></mat-icon>&nbsp;{{ 'ORGANIZATIONS.MY_ORGANIZATIONS' | translate }} ({{
                values.user?.userCount?.myOrganizationsCount
              }})
            </button>
          }
          @if (isAdmin()) {
            <button class="line-menu" mat-menu-item routerLink="/organizations/my-equipment">
              <mat-icon svgIcon="my-equipment"></mat-icon>&nbsp;{{ 'ORGANIZATIONS.MY_EQUIPMENT' | translate }} ({{ values.user?.userCount?.myEquipmentsCount }})
            </button>
          }

          <button class="line-menu" mat-menu-item routerLink="/my-case-library">
            <mat-icon svgIcon="my-records"></mat-icon>&nbsp;{{ 'ORGANIZATIONS.MY_RECORDS' | translate }} ({{ values.user?.userCount?.myLibraryCount }})
          </button>

          <button class="line-menu log-out" mat-menu-item (click)="logOut()">
            <mat-icon svgIcon="log-out-icon"></mat-icon>&nbsp;{{ 'GLOBAL.LOGOUT' | translate }}
          </button>
        }
      </mat-menu>
    </div>
  }
}
