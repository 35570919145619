import { Component, input } from '@angular/core';
import { PRIMARY_OUTLET, Router } from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  host: {
    class: 'flex justify-center',
  },
})
export class TermsAndConditionsComponent {
  callback = input<any>();
  agree = input<boolean>(false);
  gdpr = input<boolean>(false);
  lineBreak = input<boolean>(false);

  constructor(private router: Router) {}

  openLegal() {
    if (this.callback()) {
      let url = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments;
      let params: string[] = [];

      Object.entries(this.callback()).forEach(([key, value]) => {
        if (value && !key.toUpperCase().includes('PASSWORD')) {
          params.push(key + '=' + value);
        }
      });

      let returnUrl = '';
      if (params.length > 0) {
        returnUrl = url.join('/') + '?' + params.join('&');
      } else {
        returnUrl = url.join('/');
      }

      this.router.navigate(['legal'], {
        queryParams: {
          returnUrl: returnUrl,
        },
      });
    } else {
      this.router.navigate(['legal'], { queryParams: { returnUrl: this.router.url } });
    }
  }
}
