import { Component, OnInit, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'main-sidenav',
  templateUrl: './main-sidenav.component.html',
  styleUrls: ['./main-sidenav.component.scss'],
})
export class MainSidenavComponent implements OnInit {
  public activeSection: string = '';
  isAdmin = signal<boolean>(false);

  constructor(
    private router: Router,
    public organizationService: OrganisationService,
    public userService: UserService,
  ) {
    this.activeSection = this.getUrlCurrentSection(this.router.url);
    this.checkIfAdmin();
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeSection = this.getUrlCurrentSection(event.url);
      }
    });
  }

  async checkIfAdmin() {
    const isAdmin = await this.userService.isAdmin();
    this.isAdmin.set(isAdmin);
  }

  /**
   * Get the current section within organization section
   * @param url
   * @returns
   */
  getUrlCurrentSection(url: string): string {
    if (url.includes('/my-organizations')) {
      return 'my-organizations';
    } else if (url.includes('/my-case-library')) {
      return 'my-case-library';
    } else if (url.includes('/my-equipment') || url.includes('/equipment')) {
      return 'my-equipment';
    } else {
      return 'others';
    }
  }
}
