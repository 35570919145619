import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Organization, OrganizationDetails, OrganizationSettings, UserOrganization } from '../models/Organisation';
import { UserService } from './user.service';
import { MessageService } from './message.service';
import { WebSocketClientService } from './web-socket-client.service';
import { MedinboxService } from './medinbox.service';
import { WebSocketMessage } from '../models/WebSocketMessage';
import { WebSocketActionEnum } from '../models/enums/WebsocketActions';
import { OrganizationUser } from '../models/OrganizationUser';
import { RoleEnum } from '../models/enums/LiveRole';
import { Equipment } from '../models/Equipment';
import { AddUserWithRole } from '../models/AddUserWithRole';
import { RemoveOrganizationUser } from '../models/RemoveOrganizationUser';
import { EquipmentSettings } from '../models/EquipmentSettings';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  public userOrganisations$: BehaviorSubject<UserOrganization[]> = new BehaviorSubject<UserOrganization[]>([]);
  public currentOrganisations$: BehaviorSubject<UserOrganization | null> = new BehaviorSubject<UserOrganization | null>(null);

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private messageService: MessageService,
    private medinboxService: MedinboxService,
    private websocketService: WebSocketClientService,
  ) {}

  public refreshUserOrganisation(): void {
    this.httpClient.get<UserOrganization[]>(`${environment.apiUrl}/Organization/GetOrganizations`).subscribe(async (res) => {
      this.userOrganisations$.next(res);
      if (res && res.length > 0) {
        this.currentOrganisations$.next(res[0]);
      } else if (res.length == 0 && (await this.userService.isMedinboxEquipment())) {
        const ref = this.messageService.showError('This equipment has no organization attach to it');
        ref.afterClosed().subscribe(() => {
          this.websocketService
            .initMedinboxChannel(this.medinboxService.medinboxId$.getValue() as string)
            .then(() => {
              this.websocketService.sendMedinboxMessage({
                audience: 'EQUIPMENT',
                sourceId: this.userService.getCurrentUserId(),
                message: { action: WebSocketActionEnum.EXIT },
                destinationId: this.medinboxService.medinboxId$.getValue(),
              } as WebSocketMessage);
            })
            .catch((error) => {
              console.error('Error initializing Medinbox channel:', error);
            });
        });
      }
    });
  }

  /**
   * Get organization details by its ID
   * @param organizationId
   */
  public getOrganizationById(organizationId: number): Observable<OrganizationDetails> {
    return this.httpClient.get<OrganizationDetails>(`${environment.apiUrl}/Organization/GetOrganizationById?organizationId=${organizationId}`);
  }

  /**
   * Get a list of users belonging to an organization by role
   * @param organizationId
   * @param role
   * @returns a list of users
   */
  public getOrganizationUserByItsRole(organizationId: number, role: RoleEnum): Observable<OrganizationUser[]> {
    return this.httpClient.get<OrganizationUser[]>(
      `${environment.apiUrl}/Organization/GetOrganizationUsersByRole?OrganizationId=${organizationId}&RoleId=${role}`,
    );
  }

  /**
   * Get user organisations
   */
  public getUserOrganizations(): void {
    this.httpClient
      .get<UserOrganization[]>(`${environment.apiUrl}/Organization/GetOrganizations`)
      .pipe(
        catchError((err) => {
          this.userOrganisations$.error(err);
          return of([]);
        }),
      )
      .subscribe(async (orga) => {
        this.userOrganisations$.next(orga);
      });
  }

  /**
   * Create a new organization
   */
  public createOrganization(organization: Organization): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/Organization/CreateOrganization`, organization);
  }

  /**
   * Get a list of equipment belonging to an organization
   * @param organizationId
   */
  public getEquipmentWithinOrganization(organizationId: number): Observable<Equipment[]> {
    return this.httpClient.get<Equipment[]>(`${environment.apiUrl}/Organization/GetEquipmentWithinOrganization?organizationId=${organizationId}`);
  }

  /**
   * Invite new users with a specific role to join an organization
   * @param usersToInvite
   */
  public inviteUserWithRoleInOrganization(usersToInvite: AddUserWithRole): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/Organization/AddUserWithRoleInOrganization`, usersToInvite);
  }

  /**
   * Get organization default equipment settings
   * @param organizationId
   * @returns
   */
  public getOrganizationDefaultSettings(organizationId: number): Observable<any> {
    return this.httpClient.get<EquipmentSettings>(`${environment.apiUrl}/Organization/GetOrganizationDefaultSettings?organizationId=${organizationId}`);
  }

  /**
   * Update organization default equipment settings
   * @param organizationId
   * @param settings
   */
  public updateOrganizationDefaultSettings(organizationId?: number, settings?: OrganizationSettings): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/Organization/UpdateOrganizationDefaultSettings?organizationId=${organizationId}`, settings);
  }

  /**
   * Update organization details
   * @param organizationId
   * @param details
   */
  public updateOrganization(organizationId: number, details: OrganizationDetails): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/Organization/UpdateOrganization?organizationId=${organizationId}`, details);
  }

  /**
   * Return a list of equipment that not belong to any organization
   */
  public getEquipmentWithoutOrganization(): Observable<Equipment[]> {
    return this.httpClient.get<Equipment[]>(`${environment.apiUrl}/Equipment/GetEquipmentWithoutOrganization`);
  }

  /**
   * Remove a list of user with a specific role in an organization
   */
  public removeUserWithRoleInOrganization(usersToRemove: RemoveOrganizationUser): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/Organization/RemoveUserWithRoleInOrganization`, usersToRemove);
  }
}
