import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChatGptService } from '../../../../shared/services/chat-gpt.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ChatGptMessage } from 'src/app/shared/models/ChatGptMessage';
import { sendUserPrompt } from 'src/app/shared/models/SendUserPrompt';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
})
export class ChatbotComponent implements OnInit {
  @Input() liveCaseTitle: string | null = null;
  @Input() liveCaseDescription: string | null = null;

  @Input({ required: true }) videoMediaFileGuid!: string;
  public currentText: string = '';
  @ViewChild('messageList', { static: false }) messageList: ElementRef | undefined;
  userColor: string;

  public userShortName = '';

  private chatGptMessagesSource = new BehaviorSubject<ChatGptMessage[]>([]);
  chatGptMessages$ = this.chatGptMessagesSource.asObservable();

  constructor(
    private chatgptService: ChatGptService,
    private userService: UserService,
    private utils: UtilsService,
    private translate: TranslateService,
  ) {
    this.userColor = this.utils.getRandomProfileColor();
    this.chatGptMessages$.subscribe(() => {
      setTimeout(() => {
        this.scrollToBottom();
      }, 150);
    });

    this.userService.getUser().subscribe((user) => {
      this.userShortName = this.utils.getShortName(user?.firstName + ' ' + user?.lastName);
    });
  }

  ngOnInit() {
    const initMessage: ChatGptMessage = {
      content: this.translate.instant('MY_LIBRARY.AI.ASSISTANT_MESSAGE'),
      role: 'system',
    };
    this.chatGptMessagesSource.next([...this.chatGptMessagesSource.getValue(), initMessage]);
  }

  keyPressedEvent(event: KeyboardEvent) {
    if (event.key == 'Enter' && !event.shiftKey) {
      this.onSendMessageToOpenAI();
      event.preventDefault();
    }
  }

  onResized(event: any) {
    if (this.messageList && event > 20) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    try {
      if (this.messageList) {
        this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight;
      }
    } catch (err) {
      console.error('An error occured', err);
    }
  };

  /**
   * Trigger a new message in the chatbox
   */
  onSendMessageToOpenAI() {
    // Add new user's prompt message in the chatbox
    this.addMessage(this.currentText, false, false);

    // Retrieve history
    const currentMessages: ChatGptMessage[] = this.chatGptMessagesSource.getValue();

    const sendUserPrompt: sendUserPrompt = {
      videoMediaFileGuid: this.videoMediaFileGuid,
      userPrompt: this.currentText,
      conversationHistory: currentMessages,
    };

    // Send loading message until we get a response from link API
    const loadingMessage = this.translate.instant('MY_LIBRARY.AI.LOADING_RESPONSE');
    this.addMessage(loadingMessage, true, true);

    this.chatgptService.sendUserPrompt(sendUserPrompt).subscribe({
      next: (response: string) => {
        this.updateGptAnswer(response);
      },
      error: () => {
        this.updateGptAnswer(this.translate.instant('MY_LIBRARY.AI.ERROR'));
      },
    });

    this.currentText = '';
  }

  /**
   * Add message to chat box
   * @param text message tex to add in the chatbox
   * @param isGpt tell if the message provide by chat gpt or not
   */
  private addMessage(text: string, isGpt: boolean, isLoadingMessage: boolean) {
    const message: ChatGptMessage = {
      content: text,
      role: isGpt ? 'system' : 'user',
    };
    const currentMessages = this.chatGptMessagesSource.value;
    this.chatGptMessagesSource.next([...currentMessages, message]);
  }

  /**
   * Update chat gpt answer in the chat box
   * @param answer
   */
  private updateGptAnswer(answer: string) {
    // Remove the last loading message
    const currentMessages = this.chatGptMessagesSource.value;
    const index = currentMessages.map((message) => message.role === 'system').lastIndexOf(true);

    if (index != -1) {
      currentMessages.splice(index, 1);
      this.chatGptMessagesSource.next([...currentMessages]);
    }
    this.addMessage(answer, true, false);
  }
}
