import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Equipment } from '../models/Equipment';
import { OrganizationUser } from '../models/OrganizationUser';
import { EquipmentSettings } from '../models/EquipmentSettings';
import { AddUsersInEquipment } from '../models/AddUsersInEquipment';
import { RoleEnum } from '../models/enums/LiveRole';
import { RemoveEquipmentUser } from '../models/RemoveEquipmentUser';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  public userEquipment$: BehaviorSubject<Equipment[]> = new BehaviorSubject<Equipment[]>([]);

  constructor(private httpClient: HttpClient) {}

  public getEquipment(): Observable<Equipment> {
    return this.httpClient.get<Equipment>(`${environment.apiUrl}/Equipment/GetEquipment`);
  }

  /**
   * Update equipment's organization
   * @param equipmentId
   * @param organizationId can be null
   */
  public updateEquipmentOrganization(equipmentId: string, organizationId?: number): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/Equipment/UpdateEquipmentOrganization?equipmentId=${equipmentId}&organizationId=${organizationId}`, null);
  }

  /**
   * Get equipment info by its ID
   * @param equipmentId
   */
  public getEquipmentById(equipmentId: string): Observable<Equipment> {
    return this.httpClient.get<Equipment>(`${environment.apiUrl}/Equipment/GetEquipmentById?equipmentId=${equipmentId}`);
  }

  /**
   * Get a list of equipment content managers belonging to an equipment
   * @param equipmentId
   */
  public getEquipmentContentManagers(equipmentId: string): Observable<OrganizationUser[]> {
    return this.httpClient.get<OrganizationUser[]>(`${environment.apiUrl}/Equipment/GetEquipmentContentManger?equipmentId=${equipmentId}`);
  }

  /**
   * Update equipment settings
   * @param equipmentId
   * @param settings
   */
  public updateEquipmentSettings(equipmentId?: string, settings?: EquipmentSettings): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/Equipment/UpdateEquipmentSettings?equipmentId=${equipmentId}`, settings);
  }

  /**
   * Add equipmnet content manager to manage equipment
   * @param usersToInvite
   */
  public InviteEquipmentContentManager(usersToInvite: AddUsersInEquipment): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/Equipment/AddEquipmentContentManager`, usersToInvite);
  }

  /**
   * Get user role for an equipment
   * @param equipmentId
   */
  public GetUserEquipmentRole(equipmentId: string): Observable<RoleEnum | undefined> {
    return this.httpClient.get<RoleEnum>(`${environment.apiUrl}/Equipment/GetUserEquipmentRole?equipmentId=${equipmentId}`);
  }

  /**
   * Remove a list of equipment content manager
   * @param usersToRemove
   */
  public removeEquipmentContentManager(usersToRemove: RemoveEquipmentUser): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/Equipment/RemoveEquipmentContentManager`, usersToRemove);
  }

  /**F
   * Get user equipment
   */
  public getUserEquipment(): void {
    this.httpClient
      .get<Equipment[]>(`${environment.apiUrl}/Equipment/GetUserEquipment`)
      .pipe(
        catchError((err) => {
          this.userEquipment$.error(err);
          return [];
        }),
      )
      .subscribe((eq) => {
        this.userEquipment$.next(eq);
      });
  }
}
