import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public status = signal<boolean>(false);
  public isTransparent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Show or hide the progress bar and the layer
   * @param show if true show the progress bar and the layer
   */
  public display(show: boolean = true, background?: boolean) {
    this.status.set(show);
    if (background) {
      this.isTransparent.next(background);
    } else {
      this.isTransparent.next(false);
    }
  }

  /** Hide the progress bar and the layer */
  public hide() {
    this.status.set(false);
  }
}
