import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { TRANSLATION_HASHES } from "./translation-hashes";

export class CachedTranslateLoader implements TranslateLoader {
    constructor(private http: HttpClient, private prefix: string = '/assets/i18n/', private suffix: string = '.json') {}
  
    public getTranslation(lang: string): Observable<any> {
      const version = TRANSLATION_HASHES[lang] || Date.now();
      const path = `${this.prefix}${lang}${this.suffix}?v=${version}`;
      return this.http.get(path);
    }
  }