import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from '../services/logging.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../services/message.service';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    private readonly logger: LoggingService,
    private translateService: TranslateService,
    private readonly messageService: MessageService,
    private readonly loader: LoaderService,
    private readonly injector: Injector,
  ) {
    super();
  }

  private readonly NETWORK_ERROR = 'Http failure response';

  handleError(error: Error) {
    if (error instanceof HttpErrorResponse) {
      this.handleErrorResponse(error);
    }

    this.logger.LogExceptionToCloudWatch(error);
    super.handleError(error);
  }

  // handle error by diplsaying a message
  handleErrorResponse(event: HttpErrorResponse) {
    this.translateService = this.injector.get(TranslateService);
    if (event.status === 0 && event.message.startsWith(this.NETWORK_ERROR)) {
      this.messageService.showError(this.translateService.instant('SHARED.ERRORS.NETWORK_ERROR'));
    } else if (event.status === 403) {
      this.messageService.showError(event.error?.message || event.statusText);
    } else if (event.status === 422 && event.error) {
      const errors = Object.values(event.error)
        .map((v: any) => {
          return v[0];
        })
        .join('</br>');
      this.messageService.showError(errors);
    } else if (event.status === 500) {
      this.messageService.showError(this.translateService.instant('SHARED.ERRORS.INTERNAL_SERVER_ERROR'));
    } else if (event.status === 404) {
      this.messageService.showError(event.message);
    } else if (event.status === 401) {
      console.log(event);
      this.messageService.showError(event.message ?? event.error?.message);
    } else if (!event.error) {
      if (!event.statusText) {
        this.messageService.showError(event.message);
      } else {
        this.messageService.showError(event.statusText);
      }
    } else {
      this.messageService.showError(event.error.message ?? event.error.title ?? this.translateService.instant('SHARED.ERRORS.INTERNAL_SERVER_ERROR'));
    }

    this.loader.hide();
  }
}
