<div class="flex">
  <h2 class="title flex-grow">{{ 'LIVE.BOOKMARKS.EDIT_BOOKMARK' | translate }}</h2>
  @if ((this.utilsService.isTouchScreen | async) == true) {
    <button (click)="delete()" class="flex">
      <mat-icon svgIcon="delete" class="delete-icon"></mat-icon>
      <span class="delete-text ml-1">{{ 'LIVE.BOOKMARKS.DELETE_BOOKMARK' | translate }}</span>
    </button>
  }
</div>
<form [formGroup]="form" class="pb-4">
  <span class="label">{{ 'LIVE.BOOKMARKS.BOOKMARK_TITLE' | translate }}</span>
  <div class="mb-2">
    <input class="text-bold" type="text" class="bg-input-link" formControlName="title" matInput tabindex="1" [ngClass]="data.isWhite ? 'white' : 'dark'" />
  </div>
  <div class="label">
    {{ 'GLOBAL.DESCRIPTION' | translate }}
  </div>
  <div class="mb-4">
    <textarea
      rows="7"
      cols="40"
      class="bg-input-link text-bold"
      type="text"
      formControlName="description"
      matInput
      tabindex="1"
      [ngStyle]="{ 'background-color': data.isWhite ? 'var(--white)' : 'var(--bg-toolbar-icon)' }"
    ></textarea>
  </div>
  <div class="flex w-full justify-between pt-4">
    <button class="w-5/12" mat-raised-button cdkFocusInitial class="button-cancel" style="width: 150px" (click)="this.cancel()">
      <span>{{ 'GLOBAL.CANCEL' | translate }}</span>
    </button>
    <button class="w-5/12" mat-raised-button cdkFocusInitial class="button-ok py-3 hover-class w-1/2" style="width: 200px" (click)="this.validate()">
      <span>{{ 'LIVE.BOOKMARKS.SAVE_THE_BOOKMARK' | translate }}</span>
    </button>
  </div>
</form>
