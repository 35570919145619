import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { provideCacheableAnimationLoader } from 'ngx-lottie';

import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DisclaimerDialogComponent } from './components/disclaimer-dialog/disclaimer-dialog.component';
import { NgxJoystickComponent } from './components/joystick/joystick.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { ApiHttpInterceptor } from './utils/ApiHttpInterceptor';
import { MatChipsModule } from '@angular/material/chips';
import { NgxResizeObserverModule } from 'ngx-resize-observer';
import { EmailDialogComponent } from './components/email-dialog/email-dialog.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { AutosizeModule } from 'ngx-autosize';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkDrag, DragDropModule } from '@angular/cdk/drag-drop';
import { LottieComponent } from 'ngx-lottie';

import { NotificationBarComponent } from './components/notification-bar/notification-bar.component';
import { PrivateTalkNotificationComponent } from './components/private-talk-notification/private-talk-notification.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BookmarkDetailComponent } from '../live/components/side-nav/bookmarks/bookmark-detail/bookmark-detail.component';
import { BookmarkFormDialogComponent } from '../live/components/side-nav/bookmarks/bookmark-detail/bookmark-form-dialog/bookmark-form-dialog.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DisplayUsersComponent } from './components/display-users/display-users.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { environment } from 'src/environments/environment';
import { MainSidenavComponent } from './components/main-sidenav/main-sidenav.component';
import { ChatbotComponent } from '../main/my-case-library/media-file/chatbot/chatbot.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    DisclaimerDialogComponent,
    TermsAndConditionsComponent,
    ProfilePictureComponent,
    NgxJoystickComponent,
    EmailDialogComponent,
    NotificationBarComponent,
    ErrorDialogComponent,
    CountrySelectorComponent,
    PrivateTalkNotificationComponent,
    BookmarkDetailComponent,
    BookmarkFormDialogComponent,
    DisplayUsersComponent,
    MainSidenavComponent,
    ManageUsersComponent,
    ChatbotComponent,
  ],
  imports: [
    A11yModule,
    AutosizeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatPaginatorModule,
    NgxSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatToolbarModule,
    NgxResizeObserverModule,
    MatTooltipModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ClipboardModule,
    MatRippleModule,
    DragDropModule,
    CdkDrag,
    LottieComponent,
    ScrollingModule,
    ExperimentalScrollingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TourMatMenuModule,
  ],
  exports: [
    A11yModule,
    AutosizeModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatCardModule,
    ReactiveFormsModule,
    MatInputModule,
    MatBadgeModule,
    MainSidenavComponent,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatMenuModule,
    ScrollingModule,
    ExperimentalScrollingModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatSnackBarModule,
    MatRadioModule,
    NgxSliderModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatSelectModule,
    TranslateModule,
    NgxResizeObserverModule,
    TermsAndConditionsComponent,
    MatRippleModule,
    ProfilePictureComponent,
    NgxJoystickComponent,
    MatExpansionModule,
    MatAutocompleteModule,
    MatChipsModule,
    NotificationBarComponent,
    CountrySelectorComponent,
    ClipboardModule,
    PrivateTalkNotificationComponent,
    DragDropModule,
    CdkDrag,
    MatDatepickerModule,
    MatNativeDateModule,
    BookmarkDetailComponent,
    BookmarkFormDialogComponent,
    TourMatMenuModule,
    DisplayUsersComponent,
    LottieComponent,
    ChatbotComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiHttpInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    provideCacheableAnimationLoader(),

    {
      provide: 'ICommunicationService',
      useExisting: environment.communicationService,
    },
    JwtHelperService,
  ],
})
export class SharedModule {}
