import { Component, Input } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { OrganizationUser } from '../../models/OrganizationUser';

@Component({
  selector: 'app-display-users',
  templateUrl: './display-users.component.html',
  styleUrls: ['./display-users.component.scss']
})
export class DisplayUsersComponent {

  @Input({ required: true }) users: OrganizationUser[] = []; 
  @Input({ required: true }) usersToDisplay: number = 0;
  
  constructor(public utils: UtilsService,) {}
}
