@if (loaderService.status()) {
  <div>
    <div
      class="fixed top-0 right-0 bottom-0 left-0"
      style="z-index: 9999999"
      [style.background]="(loaderService.isTransparent | async) == true ? 'rgba(0, 0, 0, 0.5)' : 'rgba(248, 251, 255, 1)'"
    >
      @if ((loaderService.isTransparent | async) == false) {
        <div class="flex w-full h-full justify-center items-center">
          <ng-lottie [options]="currentAnimation"></ng-lottie>
        </div>
      }
      @if ((loaderService.isTransparent | async) == true) {
        <div class="absolute top-0 w-full">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      }
    </div>
  </div>
}
<router-outlet> </router-outlet>
<span class="hidden" id="version">Website version {{ this.version }}</span>
<tour-step-template></tour-step-template>
