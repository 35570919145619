@if (this.userService.user$ | async; as user) {
  <div class="flex-none fixed mt-28">
    @if (isAdmin()) {
      <button
        routerLink="/organizations/my-organizations"
        class="rounded-md !my-2 !py-2"
        [ngClass]="this.activeSection.includes('my-organizations') ? 'line-selected' : 'line-menu'"
        mat-menu-item
      >
        <mat-icon class="icon-color" svgIcon="my-organizations"></mat-icon>&nbsp;{{ 'ORGANIZATIONS.MY_ORGANIZATIONS' | translate }} ({{
          user.userCount?.myOrganizationsCount
        }})
      </button>
    }

    @if (isAdmin()) {
      <button
        routerLink="/organizations/my-equipment"
        class="rounded-md !my-2 !py-2"
        [ngClass]="this.activeSection.includes('my-equipment') ? 'line-selected' : 'line-menu'"
        mat-menu-item
      >
        <mat-icon class="icon-color" svgIcon="my-equipment"></mat-icon>&nbsp;{{ 'ORGANIZATIONS.MY_EQUIPMENT' | translate }} ({{
          user.userCount?.myEquipmentsCount
        }})
      </button>
    }

    <button
      routerLink="/my-case-library"
      class="rounded-md !my-2 !py-2"
      [ngClass]="this.activeSection.includes('my-case-library') ? 'line-selected' : 'line-menu'"
      mat-menu-item
    >
      <mat-icon class="icon-color" svgIcon="my-records"></mat-icon>&nbsp;{{ 'ORGANIZATIONS.MY_RECORDS' | translate }} ({{ user.userCount?.myLibraryCount }})
    </button>
  </div>
}
