<div class="flex flex-col w-full max-h-[80vh] items-center">
  <span class="disclaimer-title" [innerHTML]="data.title"></span>
  <span class="disclaimer-text mt-4 break-words w-full overflow-y-auto" [innerHTML]="data.text"></span>
  <div class="flex w-full justify-center pt-4">
    <button mat-raised-button tabindex="1" [mat-dialog-close]="confirmYes" class="button-cancel">
      @if (data.yesNoButton) {
        Leave
      } @else {
        {{ 'GLOBAL.REFUSE' | translate }}
      }
    </button>

    <button mat-raised-button tabindex="0" (click)="yesButtonClick()" cdkFocusInitial class="button-ok ml-[25px]">
      @if (data.yesNoButton) {
        Save changes
      } @else {
        {{ 'GLOBAL.ACCEPT' | translate }}
      }
    </button>
  </div>
</div>
