import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, computed, forwardRef, input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Country } from '../../models/Country';
import { Observable, filter, map, of, startWith } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ReferenceDataService } from '../../services/reference-data.service';

export const CUSTOM_CONROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CountrySelectorComponent),
  multi: true,
};

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  providers: [CUSTOM_CONROL_VALUE_ACCESSOR],
})
export class CountrySelectorComponent implements OnInit, ControlValueAccessor {
  @ViewChild('trigger', { static: false, read: MatAutocompleteTrigger }) trigger!: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput') textInput!: ElementRef;
  @ViewChild('clearButton', { static: false }) clearButton!: ElementRef;

  public countryControl = new FormControl<string | Country>('');
  public selectedCountry: Country | null = null;
  public countries: Country[] = [];
  public filteredCountries!: Observable<Country[]>;

  private valueFromPatch: number | null = null;

  @Output() isGdpr = new EventEmitter<boolean>();
  alternativStyle = input(false);
  isInError = input(false);

  inputClass = computed(() => {
    const altStyle = this.alternativStyle();
    const inError = this.isInError();

    if (altStyle && inError) return 'input-error input-alt w-full-imp';
    if (altStyle) return 'input-alt w-full-imp';
    if (inError) return 'input-error w-full-imp';

    return 'w-full-imp';
  });

  constructor(private referenceDataService: ReferenceDataService) {}

  onChanged: Function = (v: any) => {};
  onTouched: Function = () => {};

  async ngOnInit(): Promise<void> {
    this.referenceDataService.countryList$
      .pipe(
        filter((cl) => cl != null && cl.length > 0),
        map((cl) => {
          this.countries = cl;
          this.setValueFromCountryId();
          this.filteredCountries = this.countryControl.valueChanges.pipe(
            startWith(''),
            map((value) => {
              const countryname = typeof value === 'string' ? value : value?.name;
              return countryname ? this.filterCountry(countryname) : this.countries.slice();
            }),
          );
        }),
      )
      .subscribe();
  }

  handleNullValue(event: any) {
    if (!event.target.value) {
      if (this.trigger) {
        this.trigger.autocomplete.options.forEach((item) => {
          item.deselect();
        });
      }
      this.setSelected(null);
      this.filteredCountries = of(this.countries.slice());
      this.countryControl.reset('');
      this.filteredCountries = this.countryControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const countryname = typeof value === 'string' ? value : value?.name;
          return countryname ? this.filterCountry(countryname) : this.countries.slice();
        }),
      );
    } else {
      const countryId = this.filterCountry(event.target.value);
      if (countryId.length == 1) {
        this.setSelected(countryId[0]);
      }
    }
  }

  registerOnChange(fn: Function) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.valueFromPatch = value;
    this.setValueFromCountryId();
  }

  setValueFromCountryId() {
    if (this.countries.length && this.valueFromPatch) {
      this.filteredCountries = of(this.countries.filter((c) => c.countryId == this.valueFromPatch));
      const selectedCountry = this.countries.find((c) => c.countryId == this.valueFromPatch);
      this.countryControl.setValue(selectedCountry ?? null);
      this.setSelected(selectedCountry ?? null, false);
      this.valueFromPatch = null;
    }
  }

  displayFn(country: Country | null) {
    if (!country) return '';
    return country.name;
  }

  public clearAutocomplete($event: any): void {
    // TODO : this is a temp hack and should be removed
    if ($event.pointerType == 'mouse') {
      if (this.trigger) {
        this.trigger.autocomplete.options.forEach((item) => {
          item.deselect();
        });
      }
      this.setSelected(null);
      this.filteredCountries = of(this.countries.slice());
      this.countryControl.reset('');
      this.filteredCountries = this.countryControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const countryname = typeof value === 'string' ? value : value?.name;
          return countryname ? this.filterCountry(countryname) : this.countries.slice();
        }),
      );
    }
  }

  private filterCountry(name: string): Country[] {
    const filterValue = name.toLowerCase();
    return this.countries.filter((c) => c.name.toLowerCase().startsWith(filterValue));
  }

  setSelected(selected: Country | null, onChange: boolean = true) {
    this.selectedCountry = selected;
    if (this.selectedCountry) {
      this.isGdpr.emit(this.selectedCountry.gdpr);
    } else {
      this.isGdpr.emit(false);
    }
    if (onChange) {
      this.onChanged(selected?.countryId);
      this.onTouched();
    }
  }
}
