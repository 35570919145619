export enum RoleEnum {
  // Live Role
  Guest = 1,
  Attendee = 2,
  Moderator = 3,
  Owner = 4,
  Equipment = 5,

  // Organization Role
  Member = 101,
  Editor = 102,
  Administator = 103,
  OrganizationEquipment = 104,
  OrganizationManager = 105,
  EquipmentContentManager = 106
}
