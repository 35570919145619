import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import { Layout, MedinboxConfiguration } from '../models/MedinboxConfiguration';
import { WebSocketMessage } from '../models/WebSocketMessage';
import { UserService } from './user.service';
import { WebSocketClientService } from './web-socket-client.service';
import { ScaleFrame } from '../models/ScaledFrame';
import { VideoSizeAndPosition } from '../models/VideoSizeAndPosition';
import { WebSocketActionEnum } from '../models/enums/WebsocketActions';
import { LoaderService } from './loader.service';
import { SourceCapture } from '../models/SourceCapture';
import { SourceMaskWithUser } from '../models/SourceMaskWithUser';

@Injectable({
  providedIn: 'root',
})
export class MedinboxService {
  public ratio = {
    defaultWidth: 1920,
    defaultHeight: 1080,
  };

  public medinboxId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public currentLayout$: BehaviorSubject<Layout | null> = new BehaviorSubject<Layout | null>(null);
  public previousLayout$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public medinboxConfiguration$: BehaviorSubject<MedinboxConfiguration | null> = new BehaviorSubject<MedinboxConfiguration | null>(null);
  public scaledFrames$: BehaviorSubject<ScaleFrame[]> = new BehaviorSubject<ScaleFrame[]>([]);
  public sourceCapture$: BehaviorSubject<SourceCapture | null> = new BehaviorSubject<SourceCapture | null>(null);
  public sourceMaskWithUser$: BehaviorSubject<SourceMaskWithUser | null> = new BehaviorSubject<SourceMaskWithUser | null>(null);
  public recordElaspedTimeInSeconds$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public selectedSourceId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

  constructor(
    private webSocketService: WebSocketClientService,
    private userService: UserService,
    private loader: LoaderService,
  ) {
    this.medinboxConfiguration$
      .pipe(
        filter((mc) => mc != null),
        map((mc) => {
          this.ratio.defaultHeight = mc?.Multiviewer.Format.Resolution.Height ?? 1080;
          this.ratio.defaultWidth = mc?.Multiviewer.Format.Resolution.Width ?? 1980;
        }),
      )
      .subscribe();
    this.webSocketService.medinboxMessage$.subscribe((message) => {
      if (message && message.audience == 'EQUIPMENT' && message.sourceId == this.medinboxId$.getValue()) {
        this.handleWebsocketMessage(message);
      }
    });
    this.medinboxId$
      .pipe(
        filter((v) => v != null),
        map((v) => {
          localStorage.setItem('equipmentId', v ?? '');
        }),
      )
      .subscribe();
  }

  private handleWebsocketMessage(message: WebSocketMessage) {
    if (message.message.action == WebSocketActionEnum.CONFIGURATION_LOADED) {
      this.medinboxConfiguration$.next(message.message.params || null);
    } else if (message.message.action == WebSocketActionEnum.CONFIGURATION_CURRENT) {
      this.currentLayout$.next(message.message.params || null);
      this.previousLayout$.next(this.currentLayout$.getValue()?.Id ?? 0);
    } else if (message.message.action == WebSocketActionEnum.RESULT && message.message.params.actionExecuted && message.message.params.layout) {
      const layout = message.message.params.layout as Layout;
      this.currentLayout$.next(layout);
    } else if (
      message.message.action == WebSocketActionEnum.RESULT &&
      message.message.params.actionExecuted &&
      typeof message.message.params.Recording === 'boolean'
    ) {
      this.webSocketService.sendMessage({
        audience: 'LIVE',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: message.liveReference,
        message: {
          action: WebSocketActionEnum.RECORD,
          params: { start: message.message.params.Recording },
        },
      } as WebSocketMessage);
      this.loader.hide();
    } else if (message.message.action == WebSocketActionEnum.PING) {
      this.webSocketService.sendMedinboxMessage({
        audience: 'EQUIPMENT',
        message: { action: WebSocketActionEnum.PONG },
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.medinboxId$.getValue(),
      } as WebSocketMessage);
    } else if (message.message.action == WebSocketActionEnum.MASK_IMAGE) {
      this.sourceCapture$.next(message.message.params);
    } else if (message.message.action == WebSocketActionEnum.GET_MASK) {
      this.sourceMaskWithUser$.next(message.message.params);
    } else if (message.message.action == WebSocketActionEnum.RECORD_TIME) {
      this.recordElaspedTimeInSeconds$.next(message.message.params);
    }
  }

  public async initMedinboxChannelAndGetCurrentConfiguration(liveReference: string) {
    await this.webSocketService.initMedinboxChannel(this.medinboxId$.getValue() as string, true);
    const getConfMessage = {
      audience: 'EQUIPMENT',
      message: { action: WebSocketActionEnum.GET_CONFIGURATION },
      sourceId: this.userService.getCurrentUserId(),
      destinationId: this.medinboxId$.getValue(),
      liveReference: liveReference,
    } as WebSocketMessage;
    await this.webSocketService.sendMedinboxMessage(getConfMessage);
  }

  getNumberOfLayouts(): Observable<number> {
    return this.medinboxConfiguration$.pipe(
      map((mc) => {
        return mc?.Multiviewer.Layouts.length ?? 0;
      }),
    );
  }

  getNumberOfSources(): Observable<number> {
    return this.medinboxConfiguration$.pipe(
      map((mc) => {
        return mc?.Sources.length ?? 0;
      }),
    );
  }

  public calculateScaledFrame(video: VideoSizeAndPosition | null) {
    const frames: ScaleFrame[] = [];
    const currentLayout = this.currentLayout$.getValue();
    const videoSize = video;
    if (videoSize && currentLayout) {
      const zIndexAttribution = currentLayout?.Frames.every((f) => f.ZIndex == 0);
      currentLayout?.Frames.forEach((f, index) => {
        const currentSource = this.medinboxConfiguration$.getValue()?.Sources.find((s) => s.Id == f.CurrentSourceId);
        const scaledFrame: ScaleFrame = {
          id: f.Id,
          isCamera: currentSource?.IsCamera ? currentSource.IsCamera : false,
          sourceId: currentSource?.Id != null ? currentSource.Id : f.DefaultSourceId,
          zIndex: zIndexAttribution ? index : f.ZIndex,
          width: (f.Width * videoSize.width) / this.ratio.defaultWidth,
          height: (f.Height * videoSize.height) / this.ratio.defaultHeight,
          startX: (f.X * videoSize.width) / this.ratio.defaultWidth + videoSize.x,
          startY: (f.Y * videoSize.height) / this.ratio.defaultHeight + videoSize.y,
          icon: currentSource?.Icon ? currentSource?.Icon : '',
        };
        frames.push(scaledFrame);
      });
      this.scaledFrames$.next(frames);
    }
  }

  public getHeadsetColorFromConfig(headsetId: number): string {
    const conf = this.medinboxConfiguration$.getValue();
    const headsetfound = conf?.Headsets.find((h) => h.Id == headsetId);
    return headsetfound?.Color.toLowerCase() ?? 'no-color';
  }
}
