export enum WebSocketActionEnum {
  APPROVED = 1,
  DENIED,
  MUTE,
  UNMUTE,
  CHAT_INIT,
  CHAT,
  WHITEBOARD,
  DRAW,
  DRAWING,
  LASER,
  ZOOM,
  STOPZOOM,
  RESETCAMERA,
  TILTPAN,
  TILTPANSTOP,
  CONFIGURATION_LOADED,
  CONFIGURATION_CURRENT,
  GET_CONFIGURATION,
  RESULT,
  LAYOUT,
  FRAME_FULLSCREEN,
  CHANGE_SOURCE,
  PAUSE,
  RESUME,
  RECORD,
  STARTED,
  ENDED,
  SHARE_SCREEN,
  SEND_VIDEO,
  MUTE_HEADSET,
  UNMUTE_HEADSET,
  ONHOOK_HEADSET,
  OFFHOOK_HEADSET,
  ONLIVE_HEADSET,
  OFFLIVE_HEADSET,
  ROUTE_HEADSET,
  UNROUTE_HEADSET,
  SET_HEADSET_STATUSES,
  CHANGE_HEADSET_NAME,
  PING,
  PONG,
  STATE,
  DRAW_INIT,
  PARTICIPANTS,
  HEADSET_STATE,
  LIVE_CONFIGURATION,
  PRIVATE_TALK,
  APPROVAL,
  CHROME_STARTED,
  EXIT,
  TRACK_BODY,
  DETECT_SCREEN,
  CAMERA_STATUS,
  LIVE_TIME,
  GET_MASK,
  UPDATE_MASK,
  MASK_IMAGE,
  REMOVE_IMAGE,
  RESET_LIVE_TIME,
  RECORD_TIME,
  MASK_PING,
  GET_BOOKMARKS,
  ADD_BOOKMARK,
  EDIT_BOOKMARK,
  DELETE_BOOKMARK,
  MUTE_SELF,
  UNMUTE_SELF,
}
