import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private errorDialogRef!: MatDialogRef<any>;

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  public showError(message: string): MatDialogRef<any> {
    if (this.errorDialogRef?.componentInstance) {
      this.errorDialogRef.componentInstance.data = { text: message };
    } else {
      this.errorDialogRef = this.dialog.open(ErrorDialogComponent, { data: { text: message } });
    }
    return this.errorDialogRef;
  }

  public showWarning(message: string) {
    this.snackBar.open(message, 'warning_amber', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['medinbox-snackbar', 'warning'],
      duration: 3000,
    });
  }

  public showSuccess(message: string) {
    this.snackBar.open(message, 'check', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['medinbox-snackbar', 'success'],
      duration: 3000,
    });
  }
}
