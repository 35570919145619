import { Component } from '@angular/core';
import { LoaderService } from './shared/services/loader.service';
import { UtilsService } from './shared/services/utils.service';
import { AnimationOptions } from 'ngx-lottie';
import packageJson from '../../package.json';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public currentAnimation: AnimationOptions = this.utilsService.getAnimations();

  public loadAnimation: AnimationOptions = {
    path: '/assets/loader.json',
  };

  public version: string = packageJson.version;

  constructor(
    public loaderService: LoaderService,
    private readonly utilsService: UtilsService,
    private readonly cookieService: CookieService,
  ) {
    this.utilsService.registerIcons();
    const cookie = this.cookieService.get('currentVersion');
    if (!cookie) {
      this.cookieService.deleteAll();
    }
    this.cookieService.set('currentVersion', this.version, { expires: 365, path: '/' });
  }
}
