@if (showSucces || inProgress) {
  <div class="absolute top-0 right-0 bottom-0 left-0" style="z-index: 9999; background: rgba(25, 25, 25, 0.7)">
    <div class="flex w-full h-full justify-center items-center">
      <ng-lottie [options]="inProgress ? inProgressAnimation : succesAnimation" (loopComplete)="inProgress ? null : closeAnimation()"></ng-lottie>
    </div>
  </div>
}

<div class="email-dialog-container">
  <div [ngClass]="{ overlay: showOverlay }"></div>

  <div class="flex justify-between invitations">
    <span class="invitations-title">{{ 'LIVE.INVITE.INVITATIONS' | translate }}</span>
    <button class="button-decline flex items-center justify-center rounded-full p-1" (click)="dialogRef.close()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="send-invitations">{{ 'LIVE.INVITE.SEND_INVITATIONS' | translate }}</div>

  <form [formGroup]="form">
    <div class="mb-2">
      <input
        [ngClass]="{ sticky: anchorInputActive }"
        tourAnchor="emailPopup"
        placeholder="{{ 'LIVE.INVITE.ADD_PEOPLE' | translate }}"
        class="email-input"
        [(ngModel)]="value"
        (keyup.enter)="add($event)"
        formControlName="email"
        type="text"
        matInput
        tabindex="1"
      />
      <button
        [ngClass]="{ sticky: anchorButtonInputActive }"
        tourAnchor="emailPopupPlusButton"
        class="add"
        [disabled]="this.form.invalid"
        (click)="add($event)"
      >
        <i class="material-icons">add</i>
      </button>
      @if (!this.form.get('email')?.errors?.pattern) {
        <mat-error>{{ errors.email }}</mat-error>
      } @else {
        <mat-error>{{ 'SHARED.ERRORS.INVALID_EMAIL' | translate }}</mat-error>
      }
    </div>
  </form>

  @if (invites.length) {
    <mat-chip-listbox class="mb-2">
      @for (invite of invites; track invite) {
        <mat-chip class="chip" (removed)="remove(invite)">
          <span class="chip-text">{{ invite.email }}</span>
          <button class="chip-button" matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      }
    </mat-chip-listbox>
  }

  <div class="button-container">
    <button
      tourAnchor="emailPopupValidation"
      mat-raised-button
      type="submit"
      class="button-ok"
      [disabled]="this.invites.length < 1 && this.form.invalid"
      (click)="sendInvitations()"
    >
      <div>{{ 'LIVE.INVITE.SEND_CONNEXION_EMAIL' | translate }}</div>
    </button>
  </div>
</div>
