import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, filter, lastValueFrom, map, Observable } from 'rxjs';
import { DrawMode } from '../models/enums/DrawMode';
import { LiveState, StateEmum } from '../models/LiveState';
import { ParticipantStream } from '../models/ParticipantStream';
import { VideoSizeAndPosition } from '../models/VideoSizeAndPosition';
import { WebSocketMessage } from '../models/WebSocketMessage';
import { ChatService } from './chat.service';
import { DrawingService } from './drawing.service';
import { LiveService } from './live.service';
import { MedinboxService } from './medinbox.service';
import { UserService } from './user.service';
import { UtilsService } from './utils.service';
import { WebSocketClientService } from './web-socket-client.service';
import { SideNavService } from './side-nav.service';
import { SideNavComponentEnum } from '../models/enums/SideNavComponentEnum';
import { WebSocketActionEnum } from '../models/enums/WebsocketActions';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as OT from '@opentok/client';
import { ICommunicationService } from './communication.service';
import { VonageSession } from '../models/VonageSession';
import { VonageApiKey, VonageToken } from '../models/VonageToken';
import { UserInformation } from '../models/LiveDto';
import { RoleEnum } from '../models/enums/LiveRole';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BookmarkService } from './bookmark.service';
import { LiveParticipant } from '../models/LiveParticipant';

@Injectable({
  providedIn: 'root',
})
export class VonageConferenceService implements ICommunicationService {
  public sessionInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentSession$: BehaviorSubject<OT.Session | null> = new BehaviorSubject<OT.Session | null>(null);
  public participantStreams$ = signal<ParticipantStream[]>([]);
  public currentVideoSize$: BehaviorSubject<VideoSizeAndPosition | null> = new BehaviorSubject<VideoSizeAndPosition | null>(null);

  public currentPublisher$ = signal<OT.Publisher | null>(null);
  public video$ = signal<any>(null);
  public screen$ = signal<any>(null);
  public isMicAllowed = signal<boolean>(true);
  public isQualityOptimizing = signal<boolean>(false);

  private participantOrder = Object.values(RoleEnum);
  subscriber: OT.Subscriber | null = null;

  constructor(
    private userService: UserService,
    private webSocketClient: WebSocketClientService,
    private utilsService: UtilsService,
    private liveService: LiveService,
    private drawingService: DrawingService,
    private httpClient: HttpClient,
    private sideNavService: SideNavService,
    private chatService: ChatService,
    private medinboxService: MedinboxService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
    private bookmarkService: BookmarkService,
  ) {
    this.medinboxService.currentLayout$
      .pipe(
        filter((layout) => layout != null),
        map((layout) => {
          const videoSize = this.currentVideoSize$.getValue();
          if (videoSize) {
            this.medinboxService.calculateScaledFrame(videoSize);
          }
        }),
      )
      .subscribe();
    this.currentVideoSize$
      .pipe(
        filter((vs): vs is VideoSizeAndPosition => vs != null),
        map((vs) => {
          this.medinboxService.calculateScaledFrame(vs);
        }),
      )
      .subscribe();
  }

  async setInputDevice(inputDeviceSelected: MediaDeviceInfo) {
    let currentPublisher = this.currentPublisher$();
    currentPublisher?.setAudioSource(inputDeviceSelected.deviceId);
    this.currentPublisher$.set(currentPublisher);
  }

  async setOutputDevice(outputDevice: MediaDeviceInfo): Promise<OT.AudioOutputDevice> {
    await OT.setAudioOutputDevice(outputDevice.deviceId);
    return OT.getActiveAudioOutputDevice();
  }

  enumerateAudioInputDevices(): Promise<any> {
    return new Promise((resolve, reject) => {
      OT.getDevices((error: OT.OTError | undefined, devices?: OT.Device[] | undefined) => {
        if (error) {
          reject(error);
        } else {
          resolve(devices);
        }
      });
    });
  }

  enumerateAudioOutputDevices(): Promise<OT.AudioOutputDevice[]> {
    return OT.getAudioOutputDevices();
  }
  getSelectedAudioInputDevice(): MediaStreamTrack | undefined {
    return this.currentPublisher$()?.getAudioSource();
  }
  getSelectedAudioOutputDevice(): Promise<OT.AudioOutputDevice> {
    return OT.getActiveAudioOutputDevice();
  }

  muteUnmuteParticipant(liveParticipantId: string) {
    if (liveParticipantId == this.liveService.currentLiveParticipant$.getValue()?.uuid) {
      this.muteUnmuteSelf();
    } else {
      const participants = this.liveService.participantList$.getValue();
      const participant = participants.find((p) => p.uuid == liveParticipantId);
      this.webSocketClient.sendUserMessage({
        audience: 'USER',
        message: { action: participant?.isMuted ? WebSocketActionEnum.UNMUTE : WebSocketActionEnum.MUTE },
        sourceId: this.userService.getCurrentUserId(),
        destinationId: liveParticipantId,
      } as WebSocketMessage);
      this.webSocketClient.sendMessage({
        audience: 'LIVE',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.liveService.currentLive$.getValue()?.reference,
        message: { action: participant?.isMuted ? WebSocketActionEnum.UNMUTE : WebSocketActionEnum.MUTE, params: { uuid: liveParticipantId } },
      } as WebSocketMessage);
      const participantIndex = participants.findIndex((p) => p.uuid == liveParticipantId);
      if (participantIndex != -1) {
        participants[participantIndex].isMuted = true;
      }
      this.liveService.participantList$.next(participants);
    }
  }

  startConference(liveRef: string): Observable<any> {
    return this.startVonageSession(liveRef);
  }

  public async startSession(): Promise<void> {
    this.sessionInitialized$.next(true);
  }

  stopLaserIfIsInprogress() {
    const laserInProgess = this.liveService.liveIsInState(StateEmum.LASER, this.userService.getCurrentUserId());
    if (laserInProgess) {
      this.drawingService.clearDraw([DrawMode.Laser], this.userService.getCurrentUserId());
    }
  }

  public async leaveSession(endConference: boolean): Promise<void> {
    if (endConference) {
      await this.endConference();
    }

    this.currentPublisher$()?.destroy();
    this.currentSession$.getValue()?.disconnect();
    this.sessionInitialized$.next(false);

    this.stopLaserIfIsInprogress();

    this.currentPublisher$.set(null);
    this.currentSession$.next(null);
    this.liveService.participantList$.next([]);
    this.video$.set(null);
    this.screen$.set(null);

    this.liveService.participantsToValidate$.next([]);
    this.liveService.participantsToValidateNotification$.next([]);
    this.chatService.chatMessages$.next([]);
    this.bookmarkService.bookmarks$.set([]);
    this.drawingService.allDrawMessages$.next([]);
    this.drawingService.localDrawMessages$.next([]);
    this.medinboxService.currentLayout$.next(null);
    this.liveService.liveState$.next(null);
    this.webSocketClient.chatMessage$.next(null);
    this.webSocketClient.drawingMessage$.next(null);
    this.webSocketClient.message$.next(null);
    this.sideNavService.selectedComponent$.next(SideNavComponentEnum.Menu);
    this.sideNavService.selectedParticipant$.next(null);
    this.sideNavService.selectedSourceId$.next(null);
    this.sideNavService.selectedParticipantInfo$.next(null);
    this.sideNavService.sideNavOpen$.next(false);
    this.participantStreams$.set([]);

    localStorage.removeItem('currentConference');
    await this.webSocketClient.closeWebSockets();
  }

  public async endConference(): Promise<void> {
    await this.webSocketClient.sendMessage({
      audience: 'LIVE',
      sourceId: this.userService.getCurrentUserId(),
      message: { action: WebSocketActionEnum.ENDED },
      destinationId: this.liveService.currentLive$.getValue()?.reference,
    } as WebSocketMessage);
    await this.webSocketClient.sendMedinboxMessage({
      audience: 'EQUIPMENT',
      sourceId: this.userService.getCurrentUserId(),
      message: { action: WebSocketActionEnum.ENDED },
      destinationId: this.medinboxService.medinboxId$.getValue(),
      liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
    } as WebSocketMessage);
    this.currentSession$.getValue()?.signal(
      {
        type: 'endLive',
      },
      (error) => {},
    );
    await lastValueFrom(this.liveService.endLive(this.liveService.currentLive$.getValue()?.reference as string));
  }

  public getSessionAccessToken(sessionId: string): Observable<VonageToken> {
    return this.httpClient.get<VonageToken>(`${environment.apiUrl}/VonageConference/GetVonageSessionAccessToken?sessionId=${sessionId}`);
  }

  public startVonageSession(reference: string): Observable<VonageSession> {
    return this.httpClient.get<VonageSession>(`${environment.apiUrl}/VonageConference/StartVonageSession?liveReference=${reference}`);
  }

  public async startStopScreenShare(isStart: boolean): Promise<void> {
    const message = {
      audience: 'LIVE',
      sourceId: this.userService.getCurrentUserId(),
      message: { action: WebSocketActionEnum.SHARE_SCREEN, params: { start: isStart } },
      destinationId: this.liveService.currentLive$.getValue()?.reference,
    } as WebSocketMessage;
    const session = this.currentSession$?.getValue();

    if (isStart && session) {
      this.startScreenShare(session, message);
    } else {
      this.stopScreenShare(session);
    }
  }

  private startScreenShare(session: OT.Session, message: WebSocketMessage) {
    let publisherOptions2 = {
      videoSource: 'screen',
      publishVideo: true,
      name: 'screen of' + this.userService.getCurrentUserId(),
      mirror: false,
      publishAudio: true,
      showControls: false,
      insertDefaultUI: false,
    };

    try {
      const shareScreenPublisher = OT.initPublisher(undefined, publisherOptions2, (initErr: any) => {
        if (initErr && initErr.code != 1500) {
          console.log('Error initializing publisher:', initErr.message);
        } else {
          session?.publish(shareScreenPublisher);
        }
      });

      shareScreenPublisher.on({
        streamCreated: (event: any) => {
          this.storeStreams(event.stream);
          this.webSocketClient.sendMessage(message);
        },
        videoElementCreated: (event: any) => {
          this.screen$.set(event.element);
        },
        streamDestroyed: (event: any) => {
          if (event.reason === 'mediaStopped') {
            const message = {
              audience: 'LIVE',
              sourceId: this.userService.getCurrentUserId(),
              message: { action: WebSocketActionEnum.SHARE_SCREEN, params: { start: false } },
              destinationId: this.liveService.currentLive$.getValue()?.reference,
            } as WebSocketMessage;
            this.webSocketClient.sendMessage(message);
          }
        },
      });

      if (shareScreenPublisher.stream) {
        this.storeStreams(shareScreenPublisher.stream, false);
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  private stopScreenShare(session: OT.Session | null) {
    const participants = this.liveService.participantList$.getValue();
    const participant = participants.find((p) => p.uuid == this.userService.getCurrentUserId());
    const streamToDisplay = {
      ...this.participantStreams$().find((s) => s.participantId == participant?.connectionId && s.stream.videoType == 'screen'),
    };

    if (streamToDisplay?.stream) {
      this.storeStreams(streamToDisplay.stream, true);

      let shareScreenPublisher = session?.getPublisherForStream(streamToDisplay.stream);
      if (shareScreenPublisher) {
        shareScreenPublisher?.destroy();
      }
    }
  }

  private getVonageApiKey(): Observable<VonageApiKey> {
    return this.httpClient.get<VonageApiKey>(`${environment.apiUrl}/VonageConference/GetVonageApiKey`);
  }

  public async joinConference(sessionId: string): Promise<string> {
    if (this.currentSession$.getValue()) {
      return 'OK';
    }

    try {
      let vonageApiKey = (await lastValueFrom(this.getVonageApiKey())).vonageApiKey;
      this.currentSession$.next(OT.initSession(vonageApiKey, sessionId));
      this.sessionInitialized$.next(true);

      const confAcces = (await lastValueFrom(this.getSessionAccessToken(sessionId))).token;
      let session = this.currentSession$.getValue();

      if (session != null) {
        this.initializeVonageListenner(session);
      }

      const sendVideoStream = this.liveService.getAppUserRights(this.userService.getCurrentUserId()).includes('canSendVideo');

      const { videoDeviceId, audioDeviceId } = await this.getDevices(sendVideoStream);

      if (!videoDeviceId && sendVideoStream) {
        return 'Cannot find video source';
      }

      const publisherOptions: OT.PublisherProperties = this.getPublisherOptions(audioDeviceId, sendVideoStream, videoDeviceId);

      const publisher = OT.initPublisher(undefined, publisherOptions);

      publisher.on({
        streamCreated: (event: any) => {
          this.storeStreams(event.stream);
        },
        videoElementCreated: (event: any) => {
          this.setVideoElement(sendVideoStream, publisherOptions, event);
        },
        audioLevelUpdated: (audioLevelUpdated: any) => {
          this.handleSelfAudioUpdate(audioLevelUpdated);
        },
      });

      session?.connect(confAcces, async (error) => {
        if (error) {
          console.log(error.message);
          return 'Something went wrong';
        }
        if (session) {
          this.publish(session, publisher);
          this.currentPublisher$.set(publisher);
        }

        if (sendVideoStream) {
          this.sendVideoInfoToWebsocket();
        } else {
          this.startStopAudio(false);
          this.isQualityOptimizing.set(true);
        }
        return 'OK';
      });
      return 'OK';
    } catch (error: any) {
      console.log(error);
      return error.message;
    }
  }

  private setVideoElement(sendVideoStream: boolean, publisherOptions: OT.PublisherProperties, event: any) {
    if (sendVideoStream && publisherOptions.videoSource != 'screen') {
      this.video$.set(event.element);
    } else if (publisherOptions.videoSource == 'screen') {
      this.screen$.set(event.element);
    }
  }

  private getPublisherOptions(audioDeviceId: null, sendVideoStream: boolean, videoDeviceId: null): OT.PublisherProperties {
    const resolution: '1920x1080' | '1280x720' | '320x240' | undefined = '1920x1080'; // Example resolution

    return {
      resolution,

      audioSource: audioDeviceId ?? undefined, // Use a specific audio device if provided
      videoSource: sendVideoStream && videoDeviceId ? videoDeviceId : null, // Use a specific video device if provided
      publishAudio: true, // Control whether the audio is published
      publishVideo: !!(sendVideoStream && videoDeviceId), // Control whether the video is published
      showControls: false,
      frameRate: 30,
      name: 'stream of ' + this.userService.getCurrentUserId(),
      mirror: false,
      insertDefaultUI: !sendVideoStream || !videoDeviceId,
    };
  }

  handleSelfAudioUpdate(audioLevelUpdated: any) {
    var now = Date.now();
    const participants = this.liveService.participantList$.getValue();
    const currentParticipant = participants.find((p) => p.uuid == this.userService.getCurrentUserId());
    if (audioLevelUpdated.audioLevel > 0.2 && currentParticipant) {
      if (!currentParticipant.activityTimestamp) {
        currentParticipant.activityTimestamp = now;
        currentParticipant.isSpeaking = false;
        this.liveService.participantList$.next(participants);
      } else if (currentParticipant.isSpeaking) {
        currentParticipant.activityTimestamp = now;
      } else if (now - currentParticipant.activityTimestamp > 1000) {
        // detected audio activity for more than 1s
        // for the first time.
        currentParticipant.isSpeaking = true;
        this.liveService.participantList$.next(participants);
      }
    } else if (currentParticipant?.activityTimestamp && now - currentParticipant.activityTimestamp > 3000) {
      // detected low audio activity for more than 3s
      if (currentParticipant.isSpeaking) {
        currentParticipant.isSpeaking = false;
        this.liveService.participantList$.next(participants);
      }
      currentParticipant.activityTimestamp = null;
    }
  }

  private sendVideoInfoToWebsocket() {
    if (!this.liveService.liveState$.getValue() || this.liveService.liveState$.getValue()?.currentStates.length == 0) {
      this.webSocketClient.sendMessage({
        audience: 'LIVE',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.liveService.currentLive$.getValue()?.reference ?? 'no live found',
        message: { action: WebSocketActionEnum.SEND_VIDEO },
      });
      this.liveService.liveState$.next({
        liveReference: this.liveService.currentLive$.getValue()?.reference ?? 'no live found',
        isRecording: false,
        statesHistory: [[]],
        currentStates: [
          {
            timeStamp: new Date().getTime(),
            stateType: StateEmum.SEND_VIDEO,
            user: this.userService.getCurrentUserId(),
          },
        ],
      } as LiveState);
    }
  }

  private publish(session: OT.Session, publisher: OT.Publisher) {
    session?.publish(publisher);
  }

  private async getDevices(sendVideoStream: boolean) {
    let videoDeviceId: any = null;
    let audioDeviceId = null;
    await navigator.mediaDevices
      .getUserMedia({ audio: true, video: sendVideoStream })
      .then(async (s) => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        videoDeviceId = sendVideoStream ? this.utilsService.getMedinboxLinkVideoDeviceId(devices) : null;
        audioDeviceId = this.utilsService.getMedinboxLinkAudioDeviceId(devices);
      })
      .catch((err) => {
        let message;
        switch (err.name) {
          case 'NotFoundError':
          case 'DevicesNotFoundError':
            message = 'Device not found';
            break;

          case 'NotReadableError':
          case 'TrackStartError':
            message = 'Webcam or mic are already in use';
            break;

          case 'OverconstrainedError':
          case 'ConstraintNotSatisfiedError':
            message = 'Constraints can not be satisfied by avb. devices';
            break;

          case 'NotAllowedError':
          case 'PermissionDeniedError':
            message = 'Permission denied in browser';
            this.isMicAllowed.set(false);
            return { videoDeviceId, audioDeviceId: null };

          case 'TypeError':
            message = 'Empty constraints object';
            break;

          default:
            console.log(err);
            throw err; // throw any other errors that weren't handled above
        }
        throw new Error(message);
      });
    return { videoDeviceId, audioDeviceId };
  }

  private handleKick() {
    if (this.dialog.openDialogs.length > 0) return;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      data: {
        yesNoDialog: false,
        title: this.translate.instant('LIVE.LIVE.KICKED'),
      },
      panelClass: 'custom-modal',
    });
    //Promise returned in function argument where a void return was expected.
    dialogRef.afterClosed().subscribe(() => {
      this.handleDialogKickClose();
    });
  }

  private async handleDialogKickClose(): Promise<void> {
    await this.leaveSession(false);
    await this.liveService.leaveLive();
    this.router.navigate(['']);
  }

  public async startStopAudio(isStart: boolean) {
    this.currentPublisher$()?.publishAudio(isStart);
  }

  public async kickParticipant(userId: string) {
    const user = this.liveService.participantList$.getValue().find((participant) => participant.uuid === userId);

    // If the user is found, proceed with creating the liveConnection object
    if (user) {
      const liveConnection = {
        sessionId: this.currentSession$.getValue()?.sessionId,
        connectionId: user.connectionId,
      };
      await lastValueFrom(this.httpClient.patch<any>(`${environment.apiUrl}/VonageConference/KickUserFromSession`, liveConnection));
    } else {
      // Optionally handle the case where the user is not found
      console.warn(`Participant with userId ${userId} not found.`);
    }
  }

  public muteUnmuteSelf() {
    const currentParticipant = this.liveService.currentLiveParticipant$.getValue();
    if (currentParticipant) {
      this.webSocketClient.sendMessage({
        audience: 'LIVE',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.liveService.currentLive$.getValue()?.reference,
        message: {
          action: currentParticipant.isMuted ? WebSocketActionEnum.UNMUTE_SELF : WebSocketActionEnum.MUTE_SELF,
          params: { uuid: this.userService.getCurrentUserId() },
        },
      } as WebSocketMessage);

      this.startStopAudio(currentParticipant.isMuted);
      currentParticipant.isMuted = !currentParticipant.isMuted;
      const participants = this.liveService.participantList$.getValue();
      const participantIndex = participants.findIndex((p) => p.uuid == currentParticipant.uuid);
      if (participantIndex) {
        participants[participantIndex].isMuted = currentParticipant.isMuted;
      }
      this.liveService.currentLiveParticipant$.next(currentParticipant);
      this.liveService.participantList$.next(participants);
    }
  }

  public initializeVonageListenner(session: OT.Session) {
    session.on('connectionCreated', (event) => {
      this.addParticipant(event);
    });

    session.on('connectionDestroyed', (event) => {
      this.removeParticipant(event);
    });

    session.on('signal:endLive', (event) => {
      this.currentPublisher$()?.destroy();
      this.currentSession$.getValue()?.disconnect();
    });

    session.on('sessionDisconnected', (event) => {
      if (event.reason == 'forceDisconnected') {
        this.handleKick();
      }
    });

    session.on('streamCreated', (eventStreamCreated) => {
      let currentList = this.liveService.participantList$.getValue();
      let participant = currentList.find((p) => p.connectionId == eventStreamCreated.stream.connection.connectionId);
      if (participant) {
        participant.streamId = eventStreamCreated.stream.streamId;
        this.liveService.participantList$.next(currentList);

        const subscribersOptions: OT.SubscriberProperties = {
          insertDefaultUI: false,
          showControls: false,
          height: 1080,
          width: 1920,
          subscribeToAudio: true,
          subscribeToVideo: eventStreamCreated.stream.hasVideo,
          preferredResolution: { width: 1920, height: 1080 },
          // Additional constraints
        };
        this.subscriber = session.subscribe(eventStreamCreated.stream, undefined, subscribersOptions);

        // Check every second (1000 milliseconds)
        if (eventStreamCreated.stream.hasVideo) {
          let seconds = 0;
          const checkVideoWidth = () => {
            let videoWidth = this.subscriber?.videoWidth() ?? 0;
            seconds += 1;

            if (videoWidth >= 1280 || seconds >= 10) {
              this.isQualityOptimizing.set(false);
              clearInterval(intervalId); // Stop checking once the width is 1280 or loading is displayed for more than 10 secs
            }
          };
          const intervalId = setInterval(checkVideoWidth, 1000);
        }
        this.subscriber.on({
          videoElementCreated: (eventVideo: any) => {
            this.handleNewVideoStream(eventStreamCreated, eventVideo);
          },
          audioLevelUpdated: (audioLevelUpdated: any) => {
            this.handleOtherAudioUpdate(participant, audioLevelUpdated, currentList);
          },
        });
      }
    });

    session.on('streamDestroyed', (event) => {
      if (event.stream.hasVideo) {
        if (event.stream.videoType == 'camera') {
          this.video$.set(null);
        } else if (event.stream.videoType == 'screen') {
          this.screen$.set(null);
        }
      }
      this.storeStreams(event.stream, true);
    });
  }

  private handleOtherAudioUpdate(participant: LiveParticipant | undefined, audioLevelUpdated: any, currentList: LiveParticipant[]) {
    var now = Date.now();
    if (audioLevelUpdated.audioLevel > 0.2 && participant) {
      if (!participant.activityTimestamp) {
        participant.activityTimestamp = now;
        participant.isSpeaking = false;
        this.liveService.participantList$.next(currentList);
      } else if (participant.isSpeaking) {
        participant.activityTimestamp = now;
      } else if (now - participant.activityTimestamp > 1000) {
        // detected audio activity for more than 1s
        // for the first time.
        participant.isSpeaking = true;
        this.liveService.participantList$.next(currentList);
      }
    } else if (participant?.activityTimestamp && now - participant.activityTimestamp > 3000) {
      // detected low audio activity for more than 3s
      if (participant.isSpeaking) {
        participant.isSpeaking = false;
        this.liveService.participantList$.next(currentList);
      }
      participant.activityTimestamp = null;
    }
  }

  private handleNewVideoStream(eventStreamCreated: OT.Event<'streamCreated', OT.Session> & { stream: OT.Stream }, eventVideo: any) {
    if (eventStreamCreated.stream.hasVideo) {
      if (eventStreamCreated.stream.videoType == 'camera') {
        this.video$.set(eventVideo.element);
      } else if (eventStreamCreated.stream.videoType == 'screen') {
        this.screen$.set(eventVideo.element);
      }
    }
    this.storeStreams(eventStreamCreated.stream, false);
  }

  removeParticipant(event: OT.Event<'connectionDestroyed', OT.Session> & { connection: OT.Connection; reason: string }) {
    const idToDeletes: string[] = [];
    let currentList = this.liveService.participantList$.getValue();

    currentList.forEach((p, index) => {
      if (event.connection.connectionId == p.connectionId) {
        idToDeletes.push(p.uuid);
      }
    });
    idToDeletes.forEach((i) => {
      currentList.splice(
        currentList.findIndex((p) => p.uuid == i),
        1,
      );
    });
    const sortedList = currentList.toSorted(
      (a, b) => this.participantOrder.indexOf(a.liveRole as RoleEnum) - this.participantOrder.indexOf(b.liveRole as RoleEnum),
    );
    this.liveService.participantList$.next(sortedList);
  }

  addParticipant(event: OT.Event<'connectionCreated', OT.Session> & { connection: OT.Connection }) {
    const currentList = this.liveService.participantList$.getValue();
    const currentParticipantsToValidate = this.liveService.participantsToValidate$.getValue();
    const connectionMetadata = JSON.parse(event.connection.data);
    const externalId = connectionMetadata.externalId;

    if (currentList.findIndex((cp) => cp.uuid === externalId) === -1) {
      this.liveService.editUserInfo(this.liveService.currentLive$.getValue()?.reference, externalId, event.connection.connectionId).subscribe();

      const userInfos = this.liveService.getUserInformation(externalId);

      currentList.push({
        participant: connectionMetadata,
        userInfo: userInfos as UserInformation,
        uuid: externalId as string,
        isMuted: !(userInfos?.role === RoleEnum.Equipment || userInfos?.role === RoleEnum.Owner),
        liveRole: userInfos?.role,
        appRights: this.liveService.getAppUserRights(externalId),
        connectionId: event.connection.connectionId,
        connection: event.connection,
        shortName: `${userInfos?.firstName ? userInfos.firstName[0] : ''}${userInfos?.lastName ? userInfos.lastName[0] : ''}`,
        fullName: `${userInfos?.title ? userInfos.title + ' ' : ''}
    ${userInfos?.firstName ? userInfos.firstName + ' ' : ''}
    ${userInfos?.lastName ? userInfos.lastName : ''}`,
      });
    }

    const updatedParticipantsToValidate = currentParticipantsToValidate.filter((cptv) => !currentList.some((p) => p.uuid === cptv.email));

    this.liveService.participantsToValidate$.next(updatedParticipantsToValidate);

    const sortedList = currentList.toSorted(
      (a, b) => this.participantOrder.indexOf(a.liveRole as RoleEnum) - this.participantOrder.indexOf(b.liveRole as RoleEnum),
    );

    this.liveService.participantList$.next(sortedList);
  }

  private storeStreams(stream: OT.Stream, remove: boolean = false) {
    const live = this.liveService.currentLive$.getValue();
    let streams = this.participantStreams$();
    if (remove && stream.hasVideo) {
      this.removeStream(streams, stream);
    } else if (live?.liveRights) {
      if (stream.hasVideo && stream.videoType == 'camera') {
        const videoStream = streams.find((s) => s.participantId == stream.connection.connectionId && s.stream.videoType == 'camera');
        if (videoStream) {
          videoStream.stream = stream;
        } else {
          streams.push({ participantId: stream.connection.connectionId, stream: stream });
        }
        this.participantStreams$.set([...streams]);
      } else if (stream.hasVideo && stream.videoType == 'screen') {
        this.addScreenShareStream(streams, stream);
      }
    }
  }

  private addScreenShareStream(streams: ParticipantStream[], stream: OT.Stream) {
    const shareStream = streams.find((s) => s.participantId == stream.connection.connectionId && s.stream.videoType == 'screen');
    if (shareStream) {
      shareStream.stream = stream;
    } else {
      streams.push({ participantId: stream.connection.connectionId, stream: stream });
    }
    this.participantStreams$.set([...streams]);
  }

  private removeStream(streams: ParticipantStream[], stream: OT.Stream) {
    streams = streams.filter((s) => s.stream.streamId != stream.streamId);
    this.participantStreams$.set([...streams]);
    if (stream.videoType == 'screen' && this.liveService.liveIsInState(StateEmum.SHARE_SCREEN)) {
      this.webSocketClient.sendMessage({
        audience: 'LIVE',
        sourceId: this.userService.getCurrentUserId(),
        message: { action: WebSocketActionEnum.SHARE_SCREEN, params: { start: false } },
        destinationId: this.liveService.currentLive$.getValue()?.reference,
      } as WebSocketMessage);
    }
    return streams;
  }
}
