<div class="h-full side-nav rounded-l-sm">
  <div class="flex w-full h-full flex-col justify-between p-2">
    <div class="h-[180px] justify-between">
      @if (liveCaseTitle) {
        <h1 class="title break-words mt-2">{{ liveCaseTitle }}</h1>
        @if (liveCaseDescription) {
          <p class="description break-words">{{ liveCaseDescription }}</p>
        }
        <hr class="hr" />
      }
    </div>
    <div #messageList class="flex w-fill flex-col message-list p-2" style="flex-grow: 1">
      @if (this.chatGptMessages$ | async; as messages) {
        @for (message of messages; track message) {
          <ng-container
            [ngTemplateOutlet]="message.role == 'system' ? systemMessage : userMessage"
            [ngTemplateOutletContext]="{ $implicit: message }"
          ></ng-container>
        }
      }
    </div>
    <div class="flex w-full flex justify-between items-center send-text-area">
      <!-- the input -->
      <textarea
        matInput
        class="w-5/6 h-fit"
        (resized)="onResized($event)"
        autosize
        [minRows]="1"
        [maxRows]="4"
        placeholder="{{ 'LIVE.CHAT.ASK_QUESTION' | translate }}"
        name="text"
        (keypress)="keyPressedEvent($event)"
        [(ngModel)]="this.currentText"
      ></textarea>
      <button class="w-1/6 send-button" mat-button (click)="onSendMessageToOpenAI()"><mat-icon class="!m-0" svgIcon="send-chat"></mat-icon></button>
    </div>
  </div>
</div>

<!-- User message template -->
<ng-template #userMessage let-message>
  <div class="flex w-full pb-2 flex-row-reverse">
    <div class="flex flex-col w-[50px] items-center">
      <div class="circle-initials flex items-center justify-center" [ngStyle]="{ 'background-color': userColor }">
        <span class="short-name">
          {{ this.userShortName }}
        </span>
      </div>
    </div>
    <div class="flex w-fit flex-col" style="max-width: calc(100% - 50px)">
      <span class="flex w-full show-text message-user">{{ message.content }}</span>
    </div>
  </div>
</ng-template>

<!-- System prompt message -->
<ng-template #systemMessage let-message>
  <div class="flex w-full pb-2">
    <div class="flex flex-col w-[50px] items-center">
      <div class="bg-white circle-initials flex items-center justify-center">
        <mat-icon class="!h-[36px] !w-[36px]" alt="medinbox logo" svgIcon="ia-medinbox-logo"></mat-icon>
      </div>
    </div>
    <div class="flex w-fit flex-col" style="max-width: calc(100% - 50px)">
      <div class="flex w-full show-text">{{ message.content }}</div>
    </div>
  </div>
</ng-template>
