@if (this.userService.user$ | async; as user) {
  <div class="h-full w-full flex flex-col">
    <div class="h-full">
      <div class="flex justify-center items-center w-full h-full overflow-hidden">
        <div class="flex flex-col items-center w-full max-w-lg mb-50">
          <div class="mb-28">
            <img src="assets/medinbox-logo.png" alt="medinbox logo" />
          </div>
          <div class="waiting-drawing">
            <ng-lottie [options]="options" (animationCreated)="animationCreated($event)" (loopComplete)="animationLoopComplete()"></ng-lottie>
          </div>
          <div class="flex flex-col items-center w-full max-w-md">
            <div class="description">
              <span>{{ 'MAIN.HOME.EQUIPMENT_NAME' | translate }}</span>
            </div>
            <div class="page-title my-4">
              <span>{{ user?.firstName }}&nbsp;{{ user?.lastName }}</span>
            </div>
            <button mat-raised-button class="button mt-4 !w-full" (click)="startNewLive()">
              {{ 'MAIN.HOME.START_LIVE' | translate }}
            </button>
            <button class="log-out" (click)="logOutEquipment()"><mat-icon svgIcon="log-out-icon"></mat-icon>&nbsp;{{ 'GLOBAL.EXIT' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <app-terms-and-conditions class="conditions"></app-terms-and-conditions>
  </div>
}
