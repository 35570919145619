import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Bookmark } from 'src/app/shared/models/Bookmark';
import { BookmarkFormDialogComponent } from './bookmark-form-dialog/bookmark-form-dialog.component';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { BookmarkService } from 'src/app/shared/services/bookmark.service';

@Component({
  selector: 'app-bookmark-detail',
  templateUrl: './bookmark-detail.component.html',
  styleUrls: ['./bookmark-detail.component.scss'],
})
export class BookmarkDetailComponent {
  @Input() bookmark: Bookmark | undefined;
  @Input() isWhite: boolean = false;

  isOnMobile = false;

  constructor(private dialog: MatDialog, public utilsService: UtilsService, public bookmarkService: BookmarkService) {}

  openDialog() {
    this.dialog.open(BookmarkFormDialogComponent, {
      minWidth: '300px',
      maxWidth: '500px',
      data: { bookmark: this.bookmark, isOnMobile: this.isOnMobile, isWhite: this.isWhite },
      panelClass: this.isWhite ? 'white-modal' : '',
    });
  }

  delete() {
    if (this.bookmark) {
      this.bookmarkService.deleteBookmark(this.bookmark);
    }
  }

  getFormattedTime(elapsedSeconds: number): string {
    return new Date(elapsedSeconds * 1000).toISOString().slice(11, 19);
  }
}
