@if (bookmark && (this.utilsService.isTouchScreen | async) == false) {
  <div class="hover-group">
    <div class="flex w-full my-2">
      <div class="flex flex-grow h-6">
        <mat-icon svgIcon="bookmark_blue" class="bookmark-icon mr-2"></mat-icon>
        <div class="time">{{ getFormattedTime(bookmark.TimeInSeconds) }}</div>
      </div>
      <div class="flex justify-end">
        <button class="edit-btn mr-3" (click)="openDialog()">
          <mat-icon svgIcon="draw-icon" class="draw-icon" [ngStyle]="{ stroke: isWhite ? '#919191' : 'var(--white)' }"></mat-icon>
        </button>
        <button class="delete-btn" (click)="delete()">
          <mat-icon svgIcon="delete" class="delete-icon"></mat-icon>
        </button>
      </div>
    </div>
    <div class="title mb-2">{{ bookmark.Title }}</div>
    <div class="description">{{ bookmark.Description }}</div>
  </div>
}

<!-- mobile with touch screen version -->
@if (bookmark && (this.utilsService.isTouchScreen | async) == true) {
  <button (click)="openDialog()" class="text-left">
    <div class="flex w-full my-2">
      <div class="flex flex-grow">
        <mat-icon svgIcon="bookmark_blue" class="bookmark-icon mr-2"></mat-icon>
        <div class="time">{{ getFormattedTime(bookmark.TimeInSeconds) }}</div>
      </div>
    </div>
    <div class="title mb-2">{{ bookmark.Title }}</div>
    <div class="description">{{ bookmark.Description }}</div>
  </button>
}
