import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LiveService } from 'src/app/shared/services/live.service';
import { InvitedPeopleDto } from '../../models/InvitePeopleDto';
import { RoleEnum } from '../../models/enums/LiveRole';
import { MatDialogRef } from '@angular/material/dialog';
import { SideNavService } from '../../services/side-nav.service';
import { AnimationOptions } from 'ngx-lottie';
import { FormErrorHandler } from '../../utils/FormErrorHandler';
import { TourService } from 'ngx-ui-tour-md-menu';
import { MessageService } from '../../services/message.service';
import { TranslateService } from '@ngx-translate/core';

export interface Invite {
  email: string;
}

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss'],
})
export class EmailDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EmailDialogComponent>,
    private fb: UntypedFormBuilder,
    private errorHandler: FormErrorHandler,
    public liveService: LiveService,
    public sideNavService: SideNavService,
    public tourService: TourService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {
    this.url = window.location.origin;
    this.tourService.stepShow$.subscribe((step) => {
      if (step.step.anchorId == 'emailPopup' && step.direction == 0) {
        this.showOverlay = true;
        this.anchorInputActive = true;
        this.value = 'john.doe@gmail.com';
      }
      if (step.step.anchorId == 'emailPopupPlusButton' && step.direction == 0) {
        this.anchorInputActive = false;
        this.anchorButtonInputActive = true;
      }
      if (step.step.anchorId == 'emailPopupValidation' && step.direction == 0) {
        this.invites.push({ email: 'john.doe@gmail.com' });
        this.value = '';
        this.showOverlay = false;
        this.anchorButtonInputActive = false;
      }
    });
    this.tourService.stepHide$.subscribe((step) => {
      if (step.step.anchorId == 'emailPopupValidation' && step.direction == 0) {
        this.closeAnimation();
      }
    });
    this.tourService.end$.subscribe(() => {
      this.closeAnimation();
    });
  }
  showOverlay = false;
  anchorInputActive = false;
  anchorButtonInputActive = false;

  errors: any = {};
  showSucces: boolean = false;
  inProgress: boolean = false;
  value = '';
  url = '';
  invites: Invite[] = [];
  succesAnimation: AnimationOptions = {
    path: '/assets/ok.json',
  };
  inProgressAnimation: AnimationOptions = {
    path: 'assets/loading.json',
  };

  form: UntypedFormGroup = this.fb.group({
    email: ['', [Validators.required, this.errorHandler.multipleEmailValidator]],
  });

  remove(invite: Invite): void {
    const index = this.invites.indexOf(invite);

    if (index >= 0) {
      this.invites.splice(index, 1);
    }
  }

  async sendInvitations(): Promise<void> {
    this.inProgress = true;

    let userEmails: string[] = [];

    if (this.invites.length > 0) {
      // Just one address mail filled without press enter
      userEmails = this.invites.map((i) => i.email);
    } else if (this.form.valid) {
      const email = this.form.get('email')?.value;
      userEmails = [email];
    } else {
      this.messageService.showError(this.translateService.instant('SHARED.ERRORS.INVALID_EMAIL'));
    }

    // Send an invitation to each eamil addresses
    if (userEmails.length > 0) {
      await this.liveService.inviteParticipants(userEmails);

      this.liveService
        .sendEmailInvitation({
          liveReference: this.liveService.currentLive$.getValue()?.reference ?? '',
          invitedPeoples: userEmails.map((email) => {
            return { email: email, role: RoleEnum.Guest } as InvitedPeopleDto;
          }),
        })
        .subscribe({
          error: () => this.messageService.showError(this.translateService.instant('SHARED.ERRORS.INVALID_EMAIL')),
          complete: () => {
            this.showSucces = true;
            if (this.invites.length > 0) this.invites = [];
          },
        });
    }
    this.inProgress = false;
    this.closeAnimation();
  }

  add(event: Event): void {
    if (this.form.valid) {
      const inputEmails = this.form.get('email')?.value ?? '';
      const emails = inputEmails.split(/[\s,;]+/).filter((email: string) => email.length > 0);
      emails.forEach((newEmail: string) => {
        if (this.invites.findIndex((s) => s.email.toLowerCase() == newEmail.toLowerCase()) == -1) {
          this.invites.push({ email: newEmail });
        }
      });
      this.value = '';
    }
  }

  closeAnimation() {
    this.dialogRef.close();
  }
}
