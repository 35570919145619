<div class="flex w-full h-full justify-center items-center flex-col">
  <div>
    <img src="assets/medinbox-logo.png" alt="medinbox logo" class="mb-12" />
  </div>
  <div class="loading-drawing">
    <ng-lottie [options]="options"></ng-lottie>
  </div>
  <div class="loading-title mt-4">
    <span>{{ 'MEDINBOX.LOADING_MEDINBOX.STARTING' | translate }}</span>
  </div>
  <div class="mb-36"></div>
</div>
