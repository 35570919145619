import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CreateAccount } from '../models/CreateAccountDto';
import { ParticipantInfo } from '../models/ParticipantInfo';
import { RegisterGuestDto } from '../models/RegisterGuestDto';
import { User, UserUpdateDto } from '../models/User';
import { AuthService } from './auth.service';
import { GuestInformation } from '../models/GuestInformationDto';
import { RoleEnum } from '../models/enums/LiveRole';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.user$
      .pipe(
        filter((user) => user != null),
        map((user) => {
          localStorage.setItem('currentUser', JSON.stringify(user));
        }),
      )
      .subscribe();
  }

  public getCurrentUserId(): string {
    return this.user$.getValue()?.email ?? '';
  }

  public async isAuthenticated(): Promise<boolean> {
    if (await this.authService.isLoggedIn()) {
      return true;
    }
    return false;
  }

  public async isEmailVerified(): Promise<boolean> {
    return await this.authService.emailVerified();
  }

  public async isValideUser(): Promise<boolean> {
    return (await this.isAuthenticated()) && ((await this.isEmailVerified()) || (await this.isGuest()));
  }

  public async refreshCurrentUserData(): Promise<void> {
    if ((await this.isAuthenticated()) && !this.user$.getValue()) {
      const user = await lastValueFrom(this.getUser());
      this.user$.next(user);
    }
  }

  public async isMedinboxEquipment(): Promise<boolean> {
    const approles = await this.authService.getUserApplicationRoles();
    return approles.findIndex((r) => r == 'Equipment') != -1;
  }

  public async isGuest(): Promise<boolean> {
    const approles = await this.authService.getUserApplicationRoles();
    return approles.findIndex((r) => r == 'Guest') != -1;
  }

  public async isAdmin(): Promise<boolean> {
    const approles = await this.authService.getUserApplicationRoles();
    return approles.findIndex((r) => r == 'Admin') != -1;
  }

  public registerNewUser(createAccountDto: CreateAccount): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.apiUrl}/User/RegisterUser`, createAccountDto);
  }

  public updateGuestInformation(guestInfromation: GuestInformation): Observable<any> {
    return this.httpClient.patch(`${environment.apiUrl}/User/UpdateGuestInformation`, guestInfromation);
  }

  public registerGuestForLive(registerGuestDto: RegisterGuestDto): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/User/RegisterGuestUserForLive`, registerGuestDto);
  }

  public getUser(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/User/GetUser`);
  }

  public getParticipantInfo(email: string): Observable<ParticipantInfo> {
    return this.httpClient.get(`${environment.apiUrl}/User/GetParticipantInfo?email=${encodeURIComponent(email)}`);
  }

  public updateUser(user: UserUpdateDto): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/User/UpdateUser`, user);
  }

  public getUserOrganizationRole(organizationId: number): Observable<RoleEnum> {
    return this.httpClient.get<RoleEnum>(`${environment.apiUrl}/Organization/GetOrganizationUserRole?organizationId=${organizationId}`);
  }
}
