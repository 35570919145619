import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CreateLiveDto } from '../models/CreateLiveDto';
import { RoleEnum } from '../models/enums/LiveRole';
import { Live, LiveSearchResult, UserInformation } from '../models/LiveDto';
import { LiveParticipant } from '../models/LiveParticipant';
import { WebSocketMessage } from '../models/WebSocketMessage';
import { MedinboxService } from './medinbox.service';
import { UserService } from './user.service';
import { WebSocketClientService } from './web-socket-client.service';
import { ConfAndLiveRights } from '../models/ConfAndLiveRights';
import { LiveState, StateEmum } from '../models/LiveState';
import { LiveStateEnum } from '../models/enums/LiveState';
import { InvitedPeopleDto, InvitePeopleDto } from '../models/InvitePeopleDto';
import { ChangeRoleDto } from '../models/ChangeRoleDto';
import { ChangeRightsDto } from '../models/ChangeRightsDto';
import { ParticipantToValidate } from '../models/ParticipantToValidate';
import { WebSocketActionEnum } from '../models/enums/WebsocketActions';
import { ExistingConf } from '../models/ExistingConf';

@Injectable({
  providedIn: 'root',
})
export class LiveService {
  public currentLive$: BehaviorSubject<Live | null> = new BehaviorSubject<Live | null>(null);
  public liveState$: BehaviorSubject<LiveState | null> = new BehaviorSubject<LiveState | null>(null);
  public currentrights$: BehaviorSubject<ConfAndLiveRights> = new BehaviorSubject<ConfAndLiveRights>({
    canApproveParticipant: false,
    canChangeLayout: false,
    canViewChat: false,
    canWriteChat: false,
    canControlCamera: false,
    canDraw: false,
    canGiveRigths: false,
    canInvite: false,
    canKick: false,
    canLaser: false,
    canMute: false,
    canPauseLive: false,
    canRecord: false,
    canSendAudio: false,
    canShareLink: false,
    canStopLive: false,
    canStreamVideo: false,
    canWhiteboard: false,
    canControlHeadset: false,
    canManageMask: false,
    canManageBookmark: false,
    canJoin: false,
    canSendMessage: false,
    canSendVideo: false,
    canShareFile: false,
    canShareScreen: false,
    canStream: false,
    canUpdatePermissions: false,
  });
  public participantList$: BehaviorSubject<LiveParticipant[]> = new BehaviorSubject<LiveParticipant[]>([]);
  public currentLiveParticipant$: BehaviorSubject<LiveParticipant | null> = new BehaviorSubject<LiveParticipant | null>(null);
  public participantsToValidate$: BehaviorSubject<ParticipantToValidate[]> = new BehaviorSubject<ParticipantToValidate[]>([]);
  public participantsToValidateNotification$: BehaviorSubject<ParticipantToValidate[]> = new BehaviorSubject<ParticipantToValidate[]>([]);

  public liveElaspsedSeconds$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public stateToRight: [StateEmum, string, WebSocketActionEnum][] = [
    [StateEmum.DRAWING, 'canDraw', WebSocketActionEnum.DRAWING],
    [StateEmum.LASER, 'canLaser', WebSocketActionEnum.LASER],
    [StateEmum.SHARE_SCREEN, 'canShareScreen', WebSocketActionEnum.SHARE_SCREEN],
    [StateEmum.WHITEBOARD, 'canWhiteboard', WebSocketActionEnum.WHITEBOARD],
  ];

  public appRights = [
    'canViewChat',
    'canLaser',
    'canAcceptParticipant',
    'canMute',
    'canWhiteboard',
    'canDraw',
    // 'canShareConferenceLink',
    'canControlCamera',
    'canChangeLayout',
    'canPauseLive',
    'canStopLive',
    'canControlHeadset',
    'canManageMask',
  ];

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private medinboxService: MedinboxService,
    private webSocketClient: WebSocketClientService,
  ) {
    this.currentLive$.subscribe((live) => {
      if (live) {
        this.refreshParticipantList();

        if (!this.medinboxService.medinboxId$.getValue()) {
          this.medinboxService.medinboxId$.next(live.equipmentId);
        }
      }
    });
    this.participantList$.subscribe((participants) => {
      this.currentLiveParticipant$.next(participants.find((p) => p.uuid == this.userService.getCurrentUserId()) ?? null);
    });

    this.currentLiveParticipant$
      .pipe(
        filter((participant) => participant != null),
        map((participant) => {
          let currentrights = this.currentrights$.getValue();
          currentrights.canShareScreen = participant?.appRights?.includes('canShareScreen') ?? false;
          currentrights.canRecord = participant?.appRights?.includes('canRecord') ?? false;
          currentrights.canSendAudio = participant?.appRights?.includes('canSendAudio') ?? false;
          currentrights.canStreamVideo = participant?.appRights?.includes('canSendVideo') ?? false;
          currentrights.canKick = participant?.appRights?.includes('canKick') ?? false;
          currentrights.canInvite = participant?.appRights?.includes('canInvite') ?? false;
          currentrights.canGiveRigths = participant?.appRights?.includes('canUpdatePermissions') ?? false;
          currentrights.canLaser = participant?.appRights?.includes('canLaser') ?? false;
          currentrights.canApproveParticipant = participant?.appRights?.includes('canAcceptParticipant') ?? false;
          currentrights.canDraw = participant?.appRights?.includes('canDraw') ?? false;
          currentrights.canWhiteboard = participant?.appRights?.includes('canWhiteboard') ?? false;
          currentrights.canChangeLayout = participant?.appRights?.includes('canChangeLayout') ?? false;
          currentrights.canControlCamera = participant?.appRights?.includes('canControlCamera') ?? false;
          currentrights.canViewChat = participant?.appRights?.includes('canViewChat') ?? false;
          currentrights.canWriteChat = participant?.appRights?.includes('canWriteChat') ?? false;
          currentrights.canPauseLive = participant?.appRights?.includes('canPauseLive') ?? false;
          currentrights.canStopLive = participant?.appRights?.includes('canStopLive') ?? false;
          currentrights.canMute = participant?.appRights?.includes('canMute') ?? false;
          currentrights.canControlHeadset = participant?.appRights?.includes('canControlHeadset') ?? false;
          currentrights.canManageMask = participant?.appRights?.includes('canManageMask') ?? false;
          currentrights.canManageBookmark = participant?.appRights?.includes('canManageBookmark') ?? false;
          this.currentrights$.next(currentrights);
        }),
      )
      .subscribe();

    this.currentrights$.subscribe((rights) => {
      if (rights) {
        if (
          this.medinboxService.medinboxId$.getValue() &&
          (rights.canChangeLayout ||
            rights.canControlCamera ||
            rights.canControlHeadset ||
            rights.canMute ||
            rights.canRecord ||
            rights.canPauseLive ||
            rights.canStopLive ||
            rights.canManageMask ||
            rights.canStreamVideo)
        ) {
          this.medinboxService.initMedinboxChannelAndGetCurrentConfiguration(this.currentLive$.getValue()?.reference as string);
        }
        this.checkRightsAndRemoveStateIfNeeded(rights);
      }
    });
  }

  public getLive(reference: string): Observable<Live> {
    return this.httpClient.get<Live>(`${environment.apiUrl}/Live/GetLiveByReference?reference=${reference}`);
  }

  public searchLive(name: string, liveStates: LiveStateEnum[]): Observable<LiveSearchResult[]> {
    return this.httpClient.get<LiveSearchResult[]>(`${environment.apiUrl}/Live/SearchLive`, { params: { searchString: name, liveStateIds: liveStates } });
  }

  public createLive(live: CreateLiveDto): Observable<Live> {
    return this.httpClient.post<Live>(`${environment.apiUrl}/Live/CreateLive`, live);
  }

  public isAllowedToJoinLive(liveReference: string): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/Live/IsAllowedToJoinLive?liveReference=${liveReference}`);
  }

  public startConference(reference: string): Observable<Live> {
    return this.httpClient.patch<Live>(`${environment.apiUrl}/Live/StartLive?liveReference=${reference}`, null);
  }

  public endLive(reference: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${environment.apiUrl}/Live/EndLive?liveReference=${reference}`);
  }

  public inviteToLive(inviteToLive: InvitePeopleDto): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/Live/InviteToLive`, inviteToLive);
  }

  public getUserInformation(uuid: string | undefined): UserInformation | null {
    return this.currentLive$.getValue()?.usersInformations?.find((u) => u.email == uuid) || null;
  }

  public editUserInfo(reference: any, externalId: string, connectionId: string): Observable<UserInformation | null> {
    let userInfo = {
      reference: reference,
      externalId: externalId,
      connectionId: connectionId,
    };
    return this.httpClient.patch<UserInformation | null>(`${environment.apiUrl}/Live/EditUserInfo`, userInfo);
  }

  public sendEmailInvitation(inviteToLive: InvitePeopleDto): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/Live/SendEmailInvitation`, inviteToLive);
  }

  public getLiveReferenceFromConference(conferenceId: string): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/live/reference/${conferenceId}`);
  }

  public changeUsersRole(changerole: ChangeRoleDto): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.apiUrl}/Live/ChangeUserRole`, changerole);
  }

  public giveAppRights(changeRights: ChangeRightsDto): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/Live/GiveLiveRights`, changeRights);
  }

  public revokeAppRights(changeRights: ChangeRightsDto): Observable<any> {
    return this.httpClient.patch(`${environment.apiUrl}/Live/RevokeLiveRights`, changeRights);
  }

  public getAppUserRights(uuid: string | undefined): string[] {
    if (uuid) {
      const permission = this.currentLive$.getValue()?.liveRights?.[uuid]?.linkPermissions;
      return permission || [];
    }
    return [];
  }

  public refreshParticipantList() {
    const participants = this.participantList$.getValue();
    participants.forEach((p) => {
      p.liveRole = this.getUserInformation(p.uuid)?.role;
      p.appRights = this.getAppUserRights(p.uuid);
    });
    this.participantList$.next(participants);
  }

  public denyParticipant(participantId: string) {
    this.webSocketClient.sendMessage({
      audience: 'LIVE',
      sourceId: this.userService.getCurrentUserId(),
      destinationId: this.currentLive$.getValue()?.reference,
      message: { action: WebSocketActionEnum.DENIED, params: { email: participantId } },
    } as WebSocketMessage);
    const validateList = this.participantsToValidate$.getValue();
    const participantIndex = validateList.findIndex((vl) => vl.email == participantId);
    if (participantIndex != -1) {
      validateList.splice(participantIndex, 1);
      this.participantsToValidate$.next(validateList);
      this.refreshParticipantList();
    }
  }

  public async inviteParticipants(extenralIds: string[]): Promise<any> {
    return await lastValueFrom(
      this.inviteToLive({
        liveReference: this.currentLive$.getValue()?.reference as string,
        invitedPeoples: extenralIds.map((i) => {
          return { email: i, role: RoleEnum.Guest } as InvitedPeopleDto;
        }),
      }),
    );
  }

  public async inviteParticipant(participantId: string): Promise<void> {
    const validateList = this.participantsToValidate$.getValue();
    const participantIndex = validateList.findIndex((vl) => vl.email == participantId);
    if (participantIndex != -1) {
      validateList.splice(participantIndex, 1);
      this.participantsToValidate$.next(validateList);
      const particpants = await this.inviteParticipants([participantId]);
      this.webSocketClient.sendMessage({
        audience: 'LIVE',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.currentLive$.getValue()?.reference,
        message: { action: WebSocketActionEnum.APPROVED, params: { email: participantId, accesKey: particpants[participantId] } },
      } as WebSocketMessage);
      this.refreshParticipantList();
    }
  }

  public getNumberOfConnectedParticipants(): Observable<number> {
    return this.participantList$.pipe(
      map((pl) => {
        if (pl && pl.length > 0) {
          pl = pl.filter((mixer) => mixer.uuid != 'Mixer_record');
          pl = pl.filter(
            (u) =>
              (u.participant?.status as string) == 'Connecting' ||
              (u.participant?.status as string) == 'Connected' ||
              (u.participant?.status as string) == 'Inactive',
          );
          return pl.length;
        }
        return 0;
      }),
    );
  }

  public async autoValidateParticipant(reference: string, uuid: string): Promise<boolean> {
    try {
      return await firstValueFrom(this.httpClient.post<boolean>(`${environment.apiUrl}/live/${reference}/validate`, { uuid: uuid }));
    } catch (error) {
      return false;
    }
  }

  public canMuteUnmute(liveParticipant: LiveParticipant, currentLiveParticipant: LiveParticipant): boolean {
    if (liveParticipant.uuid == currentLiveParticipant.uuid) {
      return true;
    }
    if (this.currentrights$.getValue().canMute && liveParticipant.liveRole != RoleEnum.Equipment && liveParticipant.liveRole != RoleEnum.Owner) {
      // if live participant is muted we can't if unnute we can mute him
      return !liveParticipant.isMuted;
    }
    return false;
  }

  public liveIsInState(state: StateEmum, userId: string = ''): boolean {
    const liveState = this.liveState$.getValue();
    if (liveState) {
      let filteredState = liveState.currentStates.filter((s) => s.stateType == state);
      if (userId != '') {
        filteredState = filteredState.filter((s) => s.user == userId);
      }
      return filteredState.length > 0;
    }
    return false;
  }

  public liveInStateAsync(state: StateEmum, userId: string = ''): Observable<boolean> {
    return this.liveState$.pipe(
      map((ls) => {
        if (ls) {
          let filteredState = ls.currentStates.filter((s) => s.stateType == state);
          if (userId != '') {
            filteredState = filteredState.filter((s) => s.user == userId);
          }
          return filteredState.length > 0;
        }
        return false;
      }),
    );
  }

  public async leaveLive() {
    this.currentLive$.next(null);
    await this.webSocketClient.closeWebSockets();
    localStorage.removeItem('currentLive');
    if (!(await this.userService.isMedinboxEquipment())) {
      localStorage.removeItem('equipmentId');
    }
  }

  public liveExistForEquipment(equipmentId: string | null): Observable<ExistingConf> {
    return this.httpClient.get<ExistingConf>(`${environment.apiUrl}/Live/LiveExistForEquipment?equipmentId=${equipmentId}`);
  }

  checkRightsAndRemoveStateIfNeeded(rights: ConfAndLiveRights) {
    // TODO : This should be done on the API or Websocket side;
    // here we handle the case if a rights was removed to user while having a state in the live
    const userState = this.liveState$.getValue()?.currentStates.filter((s) => s.user == this.userService.getCurrentUserId());
    if (userState && userState?.length > 0) {
      // REMOVE FROM STATE HERE IF USERS PERMISSIONS HAS BEEN REMOVED
      userState.forEach((state) => {
        const stateToRight = this.stateToRight.find(([stateEnum]) => stateEnum === state.stateType);
        const rightKey = stateToRight?.[1];
        const websocketAction = stateToRight?.[2];

        if (rightKey && !rights[rightKey as keyof ConfAndLiveRights] && websocketAction) {
          // User does not have the necessary permission
          console.log(`User lacks permission: ${rightKey} for state: ${state.stateType}`);
          this.webSocketClient.sendMessage({
            audience: 'LIVE',
            sourceId: this.userService.getCurrentUserId(),
            destinationId: this.currentLive$.getValue()?.reference,
            message: { action: websocketAction, params: { start: false } },
          } as WebSocketMessage);
        }
      });
    }
  }
}
