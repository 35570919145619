import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MedinboxModule } from './medinbox/medinbox.module';
import { NotEquipmentGuard } from './shared/guards/NotEquipmentGuard';

let routes: Routes = [];

routes = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    canActivate: [NotEquipmentGuard],
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'medinbox',
    loadChildren: () => import('./medinbox/medinbox.module').then((m) => MedinboxModule),
  },
  { path: 'live', loadChildren: () => import('./live/live.module').then((m) => m.LiveModule) },

  { path: 'legal', loadChildren: () => import('./shared/components/legal/legal.module').then((m) => m.LegalModule) },
  { path: '**', loadChildren: () => import('./shared/components/not-found/not-found.module').then((m) => m.NotFoundModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
