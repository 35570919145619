@if (this.liveService.participantsToValidateNotification$ | async; as toValidate) {
  @if (toValidate.length) {
    <div
      class="flex flex-col br-10 p-4 mt-4 notification-container"
      [ngClass]="[
        (this.sideNavService.sideNavOpen$ | async) == true ? 'opened-sidenav' : '',
        (this.audioService.showHeadSetNotificationBar$ | async) == true ? 'opened-privat-talk' : ''
      ]"
    >
      <p class="title-name break-words text-ellipsis">{{ 'LIVE.PARTICIPANT_LIST.NOTIFICATION_ACCESS' | translate }}</p>
      @for (participant of toValidate; track participant) {
        <div class="flex mt-2 justify-between items-center">
          <div class="flex items-center">
            <span class="headset-circle-name rounded-full">{{ participant.shortName }}</span>
            <span class="participant-name">{{ participant.name }}</span>
          </div>
          <div class="flex">
            <button class="button-accept flex items-center justify-center rounded-full p-1 mr-2" (click)="acceptDenyUser(true, participant.email)">
              <i class="material-icons" style="color: var(--sub-text-color)">check</i>
            </button>
            <button class="button-decline flex items-center justify-center rounded-full p-1" (click)="acceptDenyUser(false, participant.email)">
              <i class="material-icons">close</i>
            </button>
          </div>
        </div>
      }
    </div>
  }
}
