import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Bookmark } from 'src/app/shared/models/Bookmark';
import { BookmarkService } from 'src/app/shared/services/bookmark.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-bookmark-for-lib-form-dialog',
  templateUrl: './bookmark-form-dialog.component.html',
  styleUrls: ['./bookmark-form-dialog.component.scss'],
})
export class BookmarkFormDialogComponent {
  form: FormGroup = new FormGroup({});
  constructor(
    public utilsService: UtilsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<BookmarkFormDialogComponent>,
    public bookmarkService: BookmarkService,
    @Inject(MAT_DIALOG_DATA) public data: { bookmark: Bookmark; isOnMobile: boolean; isWhite: boolean }
  ) {
    this.form = this.fb.group({
      title: [data.bookmark.Title, Validators.required],
      description: [data.bookmark.Description],
    });
  }

  validate() {
    if (this.form.valid) {
      const bookmark = { ...this.form.value };
      bookmark.Description = bookmark.description.trim();
      bookmark.Title = bookmark.title.trim();
      bookmark.Id = this.data.bookmark.Id;
      bookmark.TimeInSeconds = this.data.bookmark.TimeInSeconds;
      bookmark.LiveId = this.data.bookmark.LiveId;
      bookmark.RecordName = this.data.bookmark.RecordName;
      this.bookmarkService.updateBookmark(bookmark);
    }
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  delete() {
    if (this.form.valid) {
      const bookmark = { ...this.form.value };
      bookmark.Description = bookmark.description.trim();
      bookmark.Title = bookmark.title.trim();
      bookmark.Id = this.data.bookmark.Id;
      bookmark.RecordName = this.data.bookmark.RecordName;
      this.bookmarkService.deleteBookmark(bookmark);
    }
    this.dialogRef.close();
  }
}
