<div class="flex flex-col w-full h-full items-center">
  <p class="title-text align-center break-words max-w-[500px]" [ngStyle]="{ color: data.isWhite ? 'var(--black)' : 'var(--white)' }">{{ data.title }}</p>
  @if (data.showUserBox) {
    <div class="user-box mt-4">
      <span class="user-box-text">{{ data.username }}</span>
    </div>
  }
  @if (data.subtitle) {
    <span class="subtitle-text align-center mt-4 break-words w-full" [ngStyle]="{ color: data.isWhite ? 'var(--black)' : 'var(--sub-text-color)' }"
      >{{ data.subtitle }}
    </span>
  }

  <div class="flex w-full justify-center pt-4">
    @if (data.yesNoDialog) {
      <button
        mat-raised-button
        cdkFocusInitial
        [ngClass]="{ 'button-cancel': !data.isCaseDesign, 'button-cases-cancel': data.isCaseDesign }"
        (click)="noButtonClick()"
      >
        {{ data.buttonCancelText ? data.buttonCancelText : ('GLOBAL.NO' | translate) }}
      </button>
      <button
        mat-raised-button
        (click)="yesButtonClick()"
        cdkFocusInitial
        class="button-ok ml-4"
        [ngClass]="{ 'button-ok': !data.isCaseDesign, 'button-cases-ok': data.isCaseDesign, 'button-red': data.isDelete }"
      >
        {{ data.buttonConfirmText ? data.buttonConfirmText : ('GLOBAL.YES' | translate) }}
      </button>
    } @else {
      <button mat-raised-button (click)="yesButtonClick()" cdkFocusInitial class="button-ok">
        {{ data.buttonConfirmText ? data.buttonConfirmText : ('GLOBAL.OK' | translate) }}
      </button>
    }
  </div>
  @if (data.hasCheckBox && data.checkboxText) {
    <mat-checkbox color="tertiary" [checked]="checked" (change)="updateCheckbox($event.checked)" class="w-full checkbox mt-3"
      ><span [ngStyle]="{ color: data.isWhite ? 'var(--black)' : 'var(--sub-text-color)' }">{{ data.checkboxText | translate }}</span>
    </mat-checkbox>
  }
</div>
