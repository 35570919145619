import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private unauthPassword: string = 'R5q1f0V$9^Hj1#BuOdUyt5';

  constructor(private userService: UserService, private httpClient: HttpClient) {}

  public async LogExceptionToCloudWatch(error: Error) {

    if (environment.env !== 'Local')
    {
      if (await this.userService.isAuthenticated()) {
        this.httpClient
          .post(`${environment.loggingUrl}/front/logs`, { error: { name: error.name, cause: error.cause, message: error.message, stack: error.stack } })
          .pipe(take(1))
          .subscribe();
      } else {
        this.httpClient
          .post(
            `${environment.loggingUrl}/front/unauth/logs`,
            { error: { name: error.name, cause: error.cause, message: error.message, stack: error.stack } },
            { headers: { Authorization: this.unauthPassword } }
          )
          .pipe(take(1))
          .subscribe();
      }
    }
    
  }
}
