import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisclaimerDialogData } from '../../models/DisclaimerDialogData';

@Component({
  selector: 'app-disclaimer-dialog',
  templateUrl: './disclaimer-dialog.component.html',
  styleUrls: ['./disclaimer-dialog.component.scss'],
})
export class DisclaimerDialogComponent {
  public confirmYes: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DisclaimerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DisclaimerDialogData,
  ) {}

  yesButtonClick() {
    this.confirmYes = true;
    this.dialogRef.close(this.confirmYes);
  }
}
