import { Injectable } from '@angular/core';
import { auditTime, BehaviorSubject, filter, map, Subscription } from 'rxjs';
import { DrawEvent } from '../models/DrawEvent';
import { DrawingStyle } from '../models/DrawingStyle';
import { DrawMessage } from '../models/DrawMessage';
import { DrawEventTypeEnum } from '../models/enums/DrawEventTypeEnum';
import { DrawMode } from '../models/enums/DrawMode';
import { DrawingShapeStyleEnum } from '../models/enums/DrawShapeStyleEnum';
import { StateEmum } from '../models/LiveState';
import { WebSocketMessage } from '../models/WebSocketMessage';
import { LiveService } from './live.service';
import { UserService } from './user.service';
import { WebSocketClientService } from './web-socket-client.service';
import { TextDraw } from '../models/TextDraw';
import { WebSocketActionEnum } from '../models/enums/WebsocketActions';

@Injectable({
  providedIn: 'root',
})
export class DrawingService {
  public drawStyles$: BehaviorSubject<DrawingStyle[]> = new BehaviorSubject<DrawingStyle[]>([
    {
      mode: DrawMode.Live,
      eventType: DrawEventTypeEnum.draw,
      shapeStyle: DrawingShapeStyleEnum.line,
      strokeStyle: 'yellow',
      lineWidth: 1,
    },
    { mode: DrawMode.Whiteboard, eventType: DrawEventTypeEnum.draw, shapeStyle: DrawingShapeStyleEnum.line, strokeStyle: 'black', lineWidth: 1 },
    {
      mode: DrawMode.Laser,
      eventType: DrawEventTypeEnum.draw,
      shapeStyle: DrawingShapeStyleEnum.rectangle,
      strokeStyle: 'red',
      lineWidth: 2,
    },
    {
      mode: DrawMode.Live,
      eventType: DrawEventTypeEnum.draw,
      shapeStyle: DrawingShapeStyleEnum.text,
      lineWidth: 1,
      fontSize: 16,
      strokeStyle: 'yellow',
    },
    {
      mode: DrawMode.Whiteboard,
      eventType: DrawEventTypeEnum.draw,
      shapeStyle: DrawingShapeStyleEnum.text,
      lineWidth: 1,
      fontSize: 16,
      strokeStyle: 'black',
    },
  ]);
  public allDrawMessages$: BehaviorSubject<DrawMessage[]> = new BehaviorSubject<DrawMessage[]>([]);
  public localDrawMessages$: BehaviorSubject<DrawMessage[]> = new BehaviorSubject<DrawMessage[]>([]);
  public currentShapeStyle$: BehaviorSubject<DrawingShapeStyleEnum> = new BehaviorSubject<DrawingShapeStyleEnum>(DrawingShapeStyleEnum.line);
  public clearCanvas$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private currentSubscription!: Subscription;

  constructor(
    private websocketClient: WebSocketClientService,
    private userService: UserService,
    private liveService: LiveService,
  ) {
    this.liveService.liveState$.subscribe((state) => {
      if (this.currentSubscription) {
        this.currentSubscription.unsubscribe();
      }
      if (this.liveService.liveIsInState(StateEmum.DRAWING, this.userService.getCurrentUserId()) || this.liveService.liveIsInState(StateEmum.WHITEBOARD)) {
        this.currentSubscription = this.localDrawMessages$
          .pipe(
            auditTime(200),
            filter((drawMessage) => drawMessage != null && !drawMessage.some((d) => d.dotNotPropagate == true)),
            map((drawMessage) => {
              if (this.liveService.currentrights$.getValue().canDraw || this.liveService.currentrights$.getValue().canWhiteboard)
                this.websocketClient.sendDrawingMessage({
                  audience: 'LIVE',
                  sourceId: this.userService.getCurrentUserId(),
                  message: {
                    action: WebSocketActionEnum.DRAW,
                    params: drawMessage,
                  },
                  destinationId: this.liveService.currentLive$.getValue()?.reference,
                } as WebSocketMessage);
            }),
          )
          .subscribe();
      } else if (this.liveService.liveIsInState(StateEmum.LASER, this.userService.getCurrentUserId())) {
        if (this.currentSubscription) {
          this.currentSubscription.unsubscribe();
        }
        this.clearDraw([DrawMode.Live], this.userService.getCurrentUserId());
        this.currentSubscription = this.localDrawMessages$
          .pipe(
            auditTime(50),
            filter((drawMessage) => drawMessage != null),
            map((drawMessage) => {
              if (this.liveService.currentrights$.getValue().canDraw || this.liveService.currentrights$.getValue().canWhiteboard)
                this.websocketClient.sendDrawingMessage({
                  audience: 'LIVE',
                  sourceId: this.userService.getCurrentUserId(),
                  message: {
                    action: WebSocketActionEnum.DRAW,
                    params: drawMessage,
                  },
                  destinationId: this.liveService.currentLive$.getValue()?.reference,
                } as WebSocketMessage);
            }),
          )
          .subscribe();
      } else {
        this.clearDraw([DrawMode.Live], this.userService.getCurrentUserId());
      }
    });
  }

  public addDrawEvents(shapeType: DrawingShapeStyleEnum, type: string, x: number, y: number, width: number, height: number) {
    const drawStyle = this.getDrawStyle();
    const DrawEvents: DrawEvent = {
      eventType: DrawEventTypeEnum.draw,
      shapeType: shapeType,
      type: type,
      lineWidth: drawStyle?.lineWidth,
      strokeStyle: drawStyle?.strokeStyle,
    };
    if (DrawEvents.type == 'start') {
      DrawEvents.startX = x / width;
      DrawEvents.startY = y / height;
    } else {
      DrawEvents.endX = x / width;
      DrawEvents.endY = y / height;
    }
    this.addToDrawMessages(DrawEvents);
  }

  public addLaserEvents(x: number, y: number, width: number, height: number) {
    const DrawEvents: DrawEvent = {
      eventType: DrawEventTypeEnum.draw,
      shapeType: DrawingShapeStyleEnum.rectangle,
      lineWidth: 2,
      startX: x / width,
      startY: y / height,
      strokeStyle: 'red',
    };
    this.addToDrawMessages(DrawEvents);
  }

  public addTextEvent(textDraw: TextDraw, width: number, height: number, videoWidth: number, videoHeight: number) {
    const drawStyle = this.getDrawStyle();
    const DrawEvent: DrawEvent = {
      eventType: DrawEventTypeEnum.draw,
      lineWidth: (drawStyle?.fontSize ?? 16) / videoWidth,
      shapeType: DrawingShapeStyleEnum.text,
      startX: textDraw.rectStartX / videoWidth,
      startY: textDraw.rectStartY / videoHeight,
      startXAlt: textDraw.textStartX / videoWidth,
      startYAlt: textDraw.textStartY / videoHeight,
      width: width / videoWidth,
      height: height / videoHeight,
      text: textDraw,
    };
    this.addToDrawMessages(DrawEvent);
  }

  public addRect(startX: number, startY: number, width: number, height: number, type: string, videoWidth: number, videoHeight: number) {
    const drawStyle = this.getDrawStyle();
    const DrawEvent: DrawEvent = {
      eventType: DrawEventTypeEnum.draw,
      shapeType: DrawingShapeStyleEnum.rectangle,
      startX: startX / videoWidth,
      startY: startY / videoHeight,
      width: width / videoWidth,
      height: height / videoHeight,
      lineWidth: drawStyle?.lineWidth,
      strokeStyle: drawStyle?.strokeStyle,
      type: type,
    };
    this.addToDrawMessages(DrawEvent);
  }

  public addEllipse(startX: number, startY: number, endX: number, endY: number, width: number, height: number, type: string) {
    const drawStyle = this.getDrawStyle();
    const DrawEvent: DrawEvent = {
      eventType: DrawEventTypeEnum.draw,
      shapeType: DrawingShapeStyleEnum.ellipse,
      startX: startX / width,
      startY: startY / height,
      endX: endX / width,
      endY: endY / height,
      lineWidth: drawStyle?.lineWidth,
      strokeStyle: drawStyle?.strokeStyle,
      type: type,
    };
    this.addToDrawMessages(DrawEvent);
  }

  public undo() {
    const drawMessages: DrawMessage[] = this.localDrawMessages$.getValue();
    const currentUser = this.userService.getCurrentUserId();
    const currentModes = this.getCurrentDrawModes(currentUser);
    if (currentModes.length == 1) {
      const currentMode = currentModes[0];
      const filteredDrawMessages = drawMessages.find((dm) => dm.user == currentUser && dm.mode == currentMode);
      if (filteredDrawMessages) {
        if (filteredDrawMessages.events?.length) {
          const lastIndex = filteredDrawMessages.events.length - 1;
          const lastEvent = filteredDrawMessages.events[lastIndex];
          if (lastEvent.eventType == DrawEventTypeEnum.draw) {
            if (lastEvent.shapeType != DrawingShapeStyleEnum.erase) {
              const beginIndex = filteredDrawMessages.events.map((e) => e.type).lastIndexOf('begin');
              filteredDrawMessages.events.splice(beginIndex, lastIndex + 1);
            } else {
              filteredDrawMessages.events.pop();
            }
            this.localDrawMessages$.next(drawMessages);
          }
        }
      }
    }
  }

  public redraw(context: CanvasRenderingContext2D, tempCanvasContext: CanvasRenderingContext2D, width: number, height: number) {
    if (context && tempCanvasContext) {
      context.clearRect(0, 0, width, height);
      tempCanvasContext.clearRect(0, 0, width, height);
      const user = this.userService.getCurrentUserId();
      const globalModes = this.getCurrentDrawModes();
      let localModes = this.getCurrentDrawModes(user);
      if (globalModes.indexOf(DrawMode.Whiteboard) != -1 && localModes.indexOf(DrawMode.Whiteboard) == -1) {
        localModes.push(DrawMode.Whiteboard);
      }
      const localMessages = this.localDrawMessages$.getValue().filter((dm) => localModes.indexOf(dm.mode) != -1);
      let drawMessages = this.allDrawMessages$.getValue().filter((dm) => globalModes.indexOf(dm.mode) != -1);
      if (localMessages.length > 0) {
        drawMessages.filter((dm) => dm.user != user);
      }
      drawMessages = [...drawMessages, ...localMessages];
      drawMessages.forEach((m) => {
        if (m.mode != DrawMode.Laser) {
          m.events?.forEach((e) => {
            if (e.eventType == DrawEventTypeEnum.draw) {
              context.globalCompositeOperation = 'source-over';
              context.strokeStyle = e.strokeStyle as string;
              if (e.shapeType != DrawingShapeStyleEnum.text) {
                context.lineWidth = e.lineWidth as number;
              }

              tempCanvasContext.strokeStyle = e.strokeStyle as string;
              tempCanvasContext.lineWidth = e.lineWidth as number;
              if (e.shapeType == DrawingShapeStyleEnum.line) {
                if (e.type == 'begin') {
                  context.beginPath();
                  context.moveTo((e.startX as number) * width, (e.startY as number) * height);
                }
                if (e.type == 'continue') {
                  context.lineTo((e.endX as number) * width, (e.endY as number) * height);
                  context.stroke();
                }
                if (e.type == 'end') {
                  context.closePath();
                }
              }
              if (e.shapeType == DrawingShapeStyleEnum.rectangle) {
                if (e.type == 'end') {
                  context.strokeRect((e.startX as number) * width, (e.startY as number) * height, (e.width as number) * width, (e.height as number) * height);
                } else {
                  tempCanvasContext.clearRect(0, 0, width, height);
                  tempCanvasContext.strokeRect(
                    (e.startX as number) * width,
                    (e.startY as number) * height,
                    (e.width as number) * width,
                    (e.height as number) * height,
                  );
                }
              }
              if (e.shapeType == DrawingShapeStyleEnum.ellipse) {
                if (e.type == 'end') {
                  this.drawEllipse(
                    (e.startX as number) * width,
                    (e.startY as number) * height,
                    (e.endX as number) * width,
                    (e.endY as number) * height,
                    context,
                  );
                } else {
                  tempCanvasContext.clearRect(0, 0, width, height);
                  this.drawEllipse(
                    (e.startX as number) * width,
                    (e.startY as number) * height,
                    (e.endX as number) * width,
                    (e.endY as number) * height,
                    tempCanvasContext,
                  );
                }
              }
              if (e.shapeType == DrawingShapeStyleEnum.erase) {
                context.globalCompositeOperation = 'destination-out';
                context.lineWidth = context.lineWidth * 10;

                if (e.type == 'begin') {
                  context.beginPath();
                  context.moveTo((e.startX as number) * width, (e.startY as number) * height);
                }
                if (e.type == 'continue') {
                  context.lineTo((e.endX as number) * width, (e.endY as number) * height);
                  context.stroke();
                }
                if (e.type == 'end') {
                  context.closePath();
                }
              }
              if (e.shapeType == DrawingShapeStyleEnum.text) {
                context.beginPath();
                context.fillStyle = 'rgba(48, 51, 53, 0.5)';
                context.roundRect((e.startX as number) * width, (e.startY as number) * height, (e.width as number) * width, (e.height as number) * height, 5);
                context.fill();
                context.font = `normal normal 300 ${(e.lineWidth ?? 16 / width) * width}px Lexend`;
                context.strokeStyle = '#FFFFFF';
                context.strokeText(e.text?.text ?? '', (e.startXAlt as number) * width, (e.startYAlt as number) * height);
                context.closePath();
              }
            }
          });
        } else if (m.events?.length) {
          const e = m.events[0];
          if (e) {
            context.globalCompositeOperation = 'source-over';
            context.fillStyle = 'rgba(255,0,0,0.3)';
            context.lineWidth = e.lineWidth as number;
            context.beginPath();
            context.arc((e.startX as number) * width, (e.startY as number) * height, width * 0.009, 0, Math.PI * 2, true);
            context.closePath();
            context.fill();
            context.fillStyle = 'rgba(255,0,0,1)';
            context.beginPath();
            context.arc((e.startX as number) * width, (e.startY as number) * height, width * 0.003, 0, Math.PI * 2, true);
            context.closePath();
            context.fill();
          }
        }
      });
    }
  }

  private drawEllipse(x1: number, y1: number, x2: number, y2: number, ctx: CanvasRenderingContext2D) {
    var radiusX = (x2 - x1) * 0.5, /// radius for x based on input
      radiusY = (y2 - y1) * 0.5, /// radius for y based on input
      centerX = x1 + radiusX, /// calc center
      centerY = y1 + radiusY,
      step = 0.01, /// resolution of ellipse
      a = 0, /// counter
      pi2 = Math.PI * 2 + step; /// end angle
    ctx.beginPath();
    ctx.moveTo(centerX + radiusX * Math.cos(0), centerY + radiusY * Math.sin(0));
    for (; a <= pi2; a += step) {
      ctx.lineTo(centerX + radiusX * Math.cos(a), centerY + radiusY * Math.sin(a));
    }

    ctx.stroke();
  }

  public clearDraw(modes: DrawMode[] | null = null, userId: string | null = null) {
    let currentModes: DrawMode[] = modes?.length ? modes : this.getCurrentDrawModes(userId ?? null);
    const localConcernedMessages = this.localDrawMessages$
      .getValue()
      .filter((ldm) => currentModes.findIndex((cm) => cm == ldm.mode) != -1 && (userId ? ldm.user == userId : true));
    const localNotConcernedMessages = this.localDrawMessages$
      .getValue()
      .filter((ldm) => currentModes.findIndex((cm) => cm == ldm.mode) == -1 && (userId ? ldm.user == userId : true));
    localConcernedMessages.forEach((lcdm) => (lcdm.events = []));
    const allConcernedMessages = this.allDrawMessages$
      .getValue()
      .filter((adm) => currentModes.findIndex((cm) => cm == adm.mode) != -1 && (userId ? adm.user == userId : true));
    const allNotConcernedMessages = this.allDrawMessages$
      .getValue()
      .filter((adm) => currentModes.findIndex((cm) => (cm = adm.mode)) == -1 || (userId ? adm.user != userId : true));
    allConcernedMessages.forEach((lcdm) => (lcdm.events = []));
    this.allDrawMessages$.next([...allConcernedMessages, ...allNotConcernedMessages]);
    this.localDrawMessages$.next([...localConcernedMessages, ...localNotConcernedMessages]);
    if (this.liveService.currentrights$.getValue().canDraw)
      this.websocketClient.sendDrawingMessage({
        audience: 'LIVE',
        sourceId: this.userService.getCurrentUserId(),
        message: {
          action: WebSocketActionEnum.DRAW,
          params: [...localConcernedMessages, ...allConcernedMessages],
        },
        destinationId: this.liveService.currentLive$.getValue()?.reference,
      } as WebSocketMessage);
  }

  public getDrawStyle(): DrawingStyle | null {
    const currentUser = this.userService.getCurrentUserId();
    const currentShapeStyle = this.currentShapeStyle$.getValue();
    let currentModes = this.getCurrentDrawModes(currentUser);
    if (currentModes.length == 1) {
      const currentMode = currentModes[0];
      return (
        this.drawStyles$
          .getValue()
          .find((d) => d.mode == currentMode && (currentShapeStyle == DrawingShapeStyleEnum.text ? d.shapeStyle == DrawingShapeStyleEnum.text : true)) || null
      );
    } else if (currentModes.length == 0) {
      currentModes = this.getCurrentDrawModes();
      if (currentModes.length == 1 && currentModes[0] == DrawMode.Whiteboard) {
        return (
          this.drawStyles$
            .getValue()
            .find(
              (d) => d.mode == DrawMode.Whiteboard && (currentShapeStyle == DrawingShapeStyleEnum.text ? d.shapeStyle == DrawingShapeStyleEnum.text : true),
            ) || null
        );
      }
    } else if (currentModes.some((m) => m == DrawMode.Whiteboard)) {
      return (
        this.drawStyles$
          .getValue()
          .find(
            (d) => d.mode == DrawMode.Whiteboard && (currentShapeStyle == DrawingShapeStyleEnum.text ? d.shapeStyle == DrawingShapeStyleEnum.text : true),
          ) || null
      );
    }
    return null;
  }

  public updateStyle(drawStyle: DrawingStyle) {
    let drawStyles = this.drawStyles$.getValue();
    let index = -1;
    if (drawStyle.shapeStyle == DrawingShapeStyleEnum.text) {
      index = drawStyles.findIndex((ds) => ds.mode == drawStyle.mode && ds.shapeStyle == DrawingShapeStyleEnum.text);
    } else {
      index = drawStyles.findIndex((ds) => ds.mode == drawStyle.mode);
    }
    if (index != -1) {
      drawStyles[index] = drawStyle;
      this.drawStyles$.next(drawStyles);
    }
  }

  private addToDrawMessages(drawEvents: DrawEvent) {
    const drawMessages: DrawMessage[] = this.localDrawMessages$.getValue();
    const currentUser = this.userService.getCurrentUserId();
    let currentModes = this.getCurrentDrawModes(currentUser);
    if (currentModes.length == 0) {
      const globalModes = this.getCurrentDrawModes();
      if (globalModes.indexOf(DrawMode.Whiteboard) != -1) {
        currentModes.push(DrawMode.Whiteboard);
      }
    }
    if (currentModes.length == 1) {
      const currentMode = currentModes[0];
      const filteredDrawMessages = drawMessages.filter((dm) => dm.user == currentUser && dm.mode == currentMode);

      const notConcernedMessages = drawMessages.filter((dm) => dm.user == currentUser && dm.mode != currentMode);
      if (filteredDrawMessages.length) {
        if (currentMode == DrawMode.Laser) {
          filteredDrawMessages[0].events = [drawEvents];
        } else {
          if (drawEvents.shapeType == DrawingShapeStyleEnum.text) {
            const textDrawsEnvents = filteredDrawMessages[0].events.filter((de) => de.shapeType == DrawingShapeStyleEnum.text);
            const existingTextDrawing = textDrawsEnvents.find((tde) => tde.text?.id == drawEvents.text?.id);
            if (existingTextDrawing?.text && drawEvents.text) {
              existingTextDrawing.text.text = drawEvents.text.text;
              existingTextDrawing.width = drawEvents.width;
              existingTextDrawing.height = drawEvents.height;
              existingTextDrawing.startX = drawEvents.startX;
              existingTextDrawing.startY = drawEvents.startY;
              existingTextDrawing.startXAlt = drawEvents.startXAlt;
              existingTextDrawing.startYAlt = drawEvents.startYAlt;
            } else {
              filteredDrawMessages[0].events.push(drawEvents);
            }
          } else {
            filteredDrawMessages[0].events = [...(filteredDrawMessages[0].events || []), ...[drawEvents]];
          }
        }
        filteredDrawMessages[0].dotNotPropagate = false;
      } else {
        filteredDrawMessages.push({
          user: currentUser,
          mode: currentMode,
          events: [drawEvents],
        });
      }
      const localMessages = [...filteredDrawMessages, ...notConcernedMessages];
      this.localDrawMessages$.next(localMessages);
    }
  }

  private getCurrentDrawModes(currentUser: string | null = null): DrawMode[] {
    const result: DrawMode[] = [];
    if (this.liveService.liveIsInState(StateEmum.DRAWING, currentUser || '')) {
      result.push(DrawMode.Live);
    }
    if (this.liveService.liveIsInState(StateEmum.LASER, currentUser || '')) {
      result.push(DrawMode.Laser);
    }
    if (this.liveService.liveIsInState(StateEmum.WHITEBOARD)) {
      result.push(DrawMode.Whiteboard);
    }
    return result;
  }
}
