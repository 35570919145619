import { Injectable, effect, signal } from '@angular/core';
import { BehaviorSubject, filter, map } from 'rxjs';
import { ConfAndLiveRights } from '../models/ConfAndLiveRights';
import { SideNavComponentEnum } from '../models/enums/SideNavComponentEnum';
import { LiveParticipant } from '../models/LiveParticipant';
import { ParticipantInfo } from '../models/ParticipantInfo';
import { LiveService } from './live.service';
import { ScaleFrame } from '../models/ScaledFrame';
import { MedinboxService } from './medinbox.service';
import { SourceInfo } from '../models/SourceInfo';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  public sideNavOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedComponent$: BehaviorSubject<SideNavComponentEnum> = new BehaviorSubject<SideNavComponentEnum>(SideNavComponentEnum.Menu);
  public selectedParticipant$: BehaviorSubject<LiveParticipant | null> = new BehaviorSubject<LiveParticipant | null>(null);
  public selectedParticipantInfo$: BehaviorSubject<ParticipantInfo | null> = new BehaviorSubject<ParticipantInfo | null>(null);
  public animationDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedFrame$: BehaviorSubject<ScaleFrame | null> = new BehaviorSubject<ScaleFrame | null>(null);
  public selectedIndexFrame$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public selectedSourceId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  public selectedSourceInfo$: BehaviorSubject<SourceInfo | null> = new BehaviorSubject<SourceInfo | null>(null);
  public sideNavLocked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isActive = signal<boolean>(true);
  public isTutoInProgress = signal<boolean>(false);
  public isTemporarilyLocked = signal<boolean>(false);
  public dialogRef = signal<any>(null);

  constructor(
    private liveService: LiveService,
    private medinboxService: MedinboxService,
  ) {
    this.liveService.currentrights$
      .pipe(
        filter((rights) => rights != null),
        map((rights) => {
          this.handleSideBarOnRightsChange(rights);
        }),
      )
      .subscribe();

    this.medinboxService.scaledFrames$
      .pipe(
        map((sf) => {
          let selectedFrameId = this.selectedFrame$.getValue()?.id;
          if (selectedFrameId) {
            this.selectedFrame$.next(sf.find((sf) => sf.id == selectedFrameId) || null);
          }
        }),
      )
      .subscribe();

    effect(
      () => {
        if (!this.isActive() && !this.sideNavLocked$.getValue()) {
          this.closeSideNav();
        }
      },
      {
        allowSignalWrites: true,
      },
    );

    effect(
      () => {
        if (!this.sideNavOpen$.getValue() == true && this.sideNavLocked$.getValue()) {
          this.sideNavLocked$.next(false);
        }
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  selectComponent(selectedComponent: SideNavComponentEnum, open: boolean = false) {
    this.selectedComponent$.next(selectedComponent);
    if (open) {
      this.sideNavOpen$.next(true);
    }
  }

  openSideNav() {
    this.sideNavOpen$.next(true);
  }

  closeSideNav() {
    this.sideNavOpen$.next(false);
  }

  public displaySuccess(show: boolean = true) {
    this.animationDisplay$.next(show);
  }

  /** Hide the progress bar and the layer */
  public hideSuccess() {
    this.animationDisplay$.next(false);
  }

  private handleSideBarOnRightsChange(rights: ConfAndLiveRights) {
    if (this.sideNavOpen$.getValue()) {
      const selectComponent = this.selectedComponent$.getValue();
      if (
        (!rights.canViewChat && selectComponent == SideNavComponentEnum.Chat) ||
        (!rights.canChangeLayout && selectComponent == SideNavComponentEnum.Layout) ||
        (!rights.canControlHeadset && selectComponent == SideNavComponentEnum.SoundManagement)
      ) {
        this.selectedComponent$.next(SideNavComponentEnum.Menu);
      }
    }
  }
}
