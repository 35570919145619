import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import { HeadsetStatus } from '../models/HeadsetStatus';
import { MedinboxService } from './medinbox.service';
import { WebSocketMessage } from '../models/WebSocketMessage';
import { WebSocketClientService } from './web-socket-client.service';
import { UserService } from './user.service';
import { LiveService } from './live.service';
import { StateEmum } from '../models/LiveState';
import { WebSocketActionEnum } from '../models/enums/WebsocketActions';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private colorMap = [
    { name: 'blue', value: '#3C91E6' },
    { name: 'pink', value: '#F29CA3' },
    { name: 'yellow', value: '#D9D10C' },
    { name: 'purple', value: '#921ED9' },
    { name: 'green', value: '#3AB530' },
    { name: 'no-color', value: '#BEBEBE' },
  ];

  public equipmentHeadsetStatuses$: BehaviorSubject<HeadsetStatus[]> = new BehaviorSubject<HeadsetStatus[]>([]);
  public previousEquipmentHeadsetStatuses$: BehaviorSubject<HeadsetStatus[]> = new BehaviorSubject<HeadsetStatus[]>([]);
  public conferenceHeadSetStatuses$: BehaviorSubject<HeadsetStatus[]> = new BehaviorSubject<HeadsetStatus[]>([]);
  public showHeadSetNotificationBar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private medinboxService: MedinboxService,
    private webSocketService: WebSocketClientService,
    private userService: UserService,
    private liveService: LiveService,
  ) {
    this.webSocketService.medinboxMessage$.subscribe(async (message) => {
      if (message && message.audience == 'EQUIPMENT' && message.sourceId == this.medinboxService.medinboxId$.getValue()) {
        if (
          (message.message.action == WebSocketActionEnum.RESULT && message.message.params.message.indexOf('HEADSET_STATE') != -1) ||
          message.message.params.HeadsetStatus
        ) {
          const statuses = message.message.params.HeadsetStatus as HeadsetStatus[];
          if (statuses.length && this.medinboxService.medinboxConfiguration$.getValue()) {
            this.equipmentHeadsetStatuses$.next(statuses || []);
          }
        }
      }
    });

    this.liveService.liveState$
      .pipe(
        filter((state) => state != null && state.currentStates.length > 0),
        map((state) => {
          if (this.liveService.liveIsInState(StateEmum.PRIVATE_TALK)) {
            this.showHeadSetNotificationBar$.next(true);
          } else if (this.showHeadSetNotificationBar$.getValue()) {
            this.showHeadSetNotificationBar$.next(false);
          }
        }),
      )
      .subscribe();

    this.equipmentHeadsetStatuses$
      .pipe(
        filter((hss) => hss != null && hss.length > 0),
        map(async (hss) => {
          if (await this.userService.isMedinboxEquipment()) {
            const currentConfHeadsetStatuses = this.conferenceHeadSetStatuses$.getValue();
            hss.forEach((hs) => {
              const currentIndex = currentConfHeadsetStatuses.findIndex((cchs) => cchs.HeadsetId == hs.HeadsetId);
              if (currentIndex == -1) {
                const color = this.getHeadsetColorFromConfig(hs.HeadsetId);
                currentConfHeadsetStatuses.push({
                  ...hs,
                  Color: this.colorMap.find((cm) => color.indexOf(cm.name) != -1)?.value ?? '#BEBEBE',
                  Name: `${color}`,
                  ShortName: this.getShortName(color),
                });
              } else {
                currentConfHeadsetStatuses[currentIndex].IsMuted = hs.IsMuted;
                currentConfHeadsetStatuses[currentIndex].IsOffHooked = hs.IsOffHooked;
                currentConfHeadsetStatuses[currentIndex].IsOnLive = hs.IsOnLive;
                currentConfHeadsetStatuses[currentIndex].RoutedHeadsets = hs.RoutedHeadsets;
              }
            });
            this.conferenceHeadSetStatuses$.next(currentConfHeadsetStatuses.toSorted((a, b) => a.HeadsetId - b.HeadsetId));
            const headsetMessage = {
              audience: 'LIVE',
              message: { action: WebSocketActionEnum.SET_HEADSET_STATUSES, params: currentConfHeadsetStatuses },
              sourceId: this.medinboxService.medinboxId$.getValue(),
              destinationId: 'WEBSOCKET',
            } as WebSocketMessage;
            this.webSocketService.sendMessage(headsetMessage);
          }
        }),
      )
      .subscribe();
  }

  sleep = async (waitTime: number) => new Promise((resolve) => setTimeout(resolve, waitTime));

  async startStopPrivateDiscussion(start: boolean, headsetId: number): Promise<void> {
    const otherHeadsetId = this.conferenceHeadSetStatuses$
      .getValue()
      .filter((hs) => hs.HeadsetId != headsetId)
      .map((hs) => hs.HeadsetId);

    // to do unroute and manage state
    if (start) {
      await this.startPrivateDiscussion(otherHeadsetId, headsetId);
    } else {
      this.stopPrivateDiscussion(otherHeadsetId, headsetId);
    }
    const message = {
      audience: 'LIVE',
      sourceId: this.userService.getCurrentUserId(),
      destinationId: this.liveService.currentLive$.getValue()?.reference,
      message: {
        action: WebSocketActionEnum.PRIVATE_TALK,
        params: { start: start, headsetId: headsetId, previousState: this.previousEquipmentHeadsetStatuses$.getValue() },
      },
    } as WebSocketMessage;
    this.webSocketService.sendMessage(message);
  }

  private stopPrivateDiscussion(otherHeadsetId: number[], headsetId: number) {
    const savedHeadsetsState = this.previousEquipmentHeadsetStatuses$.getValue();
    if (savedHeadsetsState) {
      otherHeadsetId.forEach((hs) => {
        const previousState = savedHeadsetsState.find((h) => h.HeadsetId == hs);
        if (previousState && previousState.IsOnLive) {
          this.webSocketService.sendMedinboxMessage({
            audience: 'EQUIPMENT',
            sourceId: this.userService.getCurrentUserId(),
            destinationId: this.medinboxService.medinboxId$.getValue(),
            message: {
              action: WebSocketActionEnum.ONLIVE_HEADSET,
              params: {
                MedinboxId: this.medinboxService.medinboxId$.getValue() as string,
                HeadsetId: hs,
              },
            },
            liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
          } as WebSocketMessage);
        }
      });
      const savedCurrentHeadset = savedHeadsetsState.find((h) => h.HeadsetId == headsetId);
      if (savedCurrentHeadset) {
        savedCurrentHeadset.RoutedHeadsets.forEach((rh) => {
          this.webSocketService.sendMedinboxMessage({
            audience: 'EQUIPMENT',
            sourceId: this.userService.getCurrentUserId(),
            destinationId: this.medinboxService.medinboxId$.getValue(),
            message: {
              action: WebSocketActionEnum.ROUTE_HEADSET,
              params: {
                MedinboxId: this.medinboxService.medinboxId$.getValue() as string,
                HeadsetId: headsetId,
                OtherHeadsetId: rh,
              },
            },
            liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
          } as WebSocketMessage);
        });
      }
    }
  }

  private async startPrivateDiscussion(otherHeadsetId: number[], headsetId: number) {
    const inPrivate = this.liveService.liveIsInState(StateEmum.PRIVATE_TALK);
    if (!inPrivate) {
      this.previousEquipmentHeadsetStatuses$.next(this.equipmentHeadsetStatuses$.getValue());
    } else {
      const currentHeadsetInPrivate = this.currentExclusiveSpeakerHeadset();
      if (currentHeadsetInPrivate != null && currentHeadsetInPrivate != -1) {
        await this.startStopPrivateDiscussion(false, currentHeadsetInPrivate);
        await this.sleep(500);
      }
    }
    otherHeadsetId.forEach((h) => {
      this.webSocketService.sendMedinboxMessage({
        audience: 'EQUIPMENT',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.medinboxService.medinboxId$.getValue(),
        message: {
          action: WebSocketActionEnum.UNROUTE_HEADSET,
          params: {
            MedinboxId: this.medinboxService.medinboxId$.getValue() as string,
            HeadsetId: headsetId,
            OtherHeadsetId: h,
          },
        },
        liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
      } as WebSocketMessage);
      this.webSocketService.sendMedinboxMessage({
        audience: 'EQUIPMENT',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.medinboxService.medinboxId$.getValue(),
        message: {
          action: WebSocketActionEnum.OFFLIVE_HEADSET,
          params: {
            MedinboxId: this.medinboxService.medinboxId$.getValue() as string,
            HeadsetId: h,
          },
        },
        liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
      } as WebSocketMessage);
    });
  }

  public getHeadsetColorFromConfig(headsetId: number): string {
    const conf = this.medinboxService.medinboxConfiguration$.getValue();
    const headsetfound = conf?.Headsets.find((h) => h.Id == headsetId);
    return headsetfound?.Color.toLowerCase() ?? 'no-color';
  }

  public getShortName(name: string): string {
    const splitted = name.trim().split(' ');
    if (splitted.length == 1) {
      const currentName = splitted[0];
      return currentName.length > 1 ? `${currentName[0]}${currentName[1]}`.toUpperCase() : `${currentName[0].toUpperCase()}`;
    } else if (splitted.length == 2) {
      return `${splitted[0][0]}${splitted[1][0]}`.toUpperCase();
    } else if (splitted.length > 2) {
      return `${splitted[0][0]}${splitted[splitted.length - 1][0]}`.toUpperCase();
    }
    return name;
  }

  currentExclusiveSpeakerHeadsetObservable(): Observable<number | null> {
    return this.liveService.liveState$.pipe(map((ls) => ls?.currentStates.find((s) => s.stateType === StateEmum.PRIVATE_TALK)?.params.headsetId ?? null));
  }

  currentExclusiveSpeakerHeadset(): number | null {
    const liveState = this.liveService.liveState$.getValue();
    return liveState?.currentStates.find((s) => s.stateType == StateEmum.PRIVATE_TALK)?.params.headsetId ?? null;
  }
}
