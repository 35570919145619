import { Component, Input, signal } from '@angular/core';
import { UserService } from '../../services/user.service';
import { WebSocketClientService } from '../../services/web-socket-client.service';
import { MedinboxService } from '../../services/medinbox.service';
import { LiveService } from '../../services/live.service';
import { AuthService } from '../../services/auth.service';
import { WebSocketMessage } from '../../models/WebSocketMessage';
import { Router } from '@angular/router';
import { LoaderService } from '../../services/loader.service';
import { WebSocketActionEnum } from '../../models/enums/WebsocketActions';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent {
  @Input() menu: boolean = true;
  @Input() equipment: boolean = false;

  isAdmin = signal<boolean>(false);
  constructor(
    private router: Router,
    public userService: UserService,
    private loader: LoaderService,
    private webSocketClient: WebSocketClientService,
    private medinboxService: MedinboxService,
    private liveService: LiveService,
    private authService: AuthService,
  ) {
    this.checkIfAdmin();
  }

  goToProfile() {
    this.router.navigate(['account', 'profile'], { queryParams: { returnUrl: this.router.url } });
  }

  gotToMyOrganizations() {
    this.router.navigate(['organizations']);
  }

  async checkIfAdmin() {
    const isAdmin = await this.userService.isAdmin();
    this.isAdmin.set(isAdmin);
  }

  async logOut() {
    if ((await this.userService.isMedinboxEquipment()) && this.webSocketClient.socketMedinbox) {
      this.webSocketClient.sendMedinboxMessage({
        audience: 'EQUIPMENT',
        sourceId: this.userService.getCurrentUserId(),
        message: { action: WebSocketActionEnum.EXIT },
        destinationId: this.medinboxService.medinboxId$.getValue(),
        liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
      } as WebSocketMessage);
    }
    this.userService.user$.next(null);
    await this.authService.signOut();
    localStorage.clear();
    this.router.navigate(['account', 'login']);
  }

  async logOutEquipment() {
    if (await this.userService.isMedinboxEquipment()) {
      this.loader.display();
      if (this.webSocketClient.socketMedinbox) {
        this.webSocketClient.sendMedinboxMessage({
          audience: 'EQUIPMENT',
          sourceId: this.userService.getCurrentUserId(),
          message: { action: WebSocketActionEnum.EXIT },
          destinationId: this.medinboxService.medinboxId$.getValue(),
          liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
        } as WebSocketMessage);
      }
      localStorage.clear();
      this.userService.user$.next(null);
      await this.authService.signOut();
      if (!this.webSocketClient.socketMedinbox) {
        this.router.navigate(['']);
      }
    }
  }
}
