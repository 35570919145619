import { Component } from '@angular/core';

import { LiveService } from '../../services/live.service';
import { AudioService } from '../../services/audio.service';
import { HeadsetStatus } from '../../models/HeadsetStatus';
import { Observable, map } from 'rxjs';
import { SideNavService } from '../../services/side-nav.service';

@Component({
  selector: 'app-private-talk-notification',
  templateUrl: './private-talk-notification.component.html',
  styleUrls: ['./private-talk-notification.component.scss'],
})
export class PrivateTalkNotificationComponent {
  constructor(
    public liveService: LiveService,
    public audioService: AudioService,
    public sideNavService: SideNavService,
  ) {}

  stopExclusiveDiscussion(headsetId: number) {
    this.audioService.startStopPrivateDiscussion(false, headsetId);
  }

  getHeadSet(headSetId: number): Observable<HeadsetStatus | null> {
    return this.audioService.conferenceHeadSetStatuses$.pipe(
      map((value) => {
        return value.find((hs) => hs.HeadsetId == headSetId) ?? null;
      }),
    );
  }

  dismissPopUp() {
    this.audioService.showHeadSetNotificationBar$.next(false);
  }
}
