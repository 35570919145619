import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from '../../models/ConfirmDialogData';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
  ) {}

  checked: boolean = false;

  yesButtonClick() {
    if (this.data.hasCheckBox) {
      this.dialogRef.close({ confirmbutton: true, checked: this.checked });
    } else {
      this.dialogRef.close(true);
    }
  }

  noButtonClick() {
    if (this.data.hasCheckBox) {
      this.dialogRef.close({ confirmbutton: false, checked: this.checked });
    } else {
      this.dialogRef.close(false);
    }
  }

  updateCheckbox(checked: boolean) {
    this.checked = checked;
  }
}
