import { VonageConferenceService } from 'src/app/shared/services/vonage-session.service';

export const environment = {
  production: false,
  apiUrl: 'https://api.dev.aws.medinbox.link',
  loggingUrl: 'https://logging.dev.aws.medinbox.link',
  webSocketUrl: 'https://websocket.dev.aws.medinbox.link',
  env: 'Dev',
  gpt_model: 'gpt-4o',
  gpt_api_key:
    'sk-svcacct-pwqo8DCMMRIH_d56NSetayLBDI2IYPu-orTmGW0UD8IyeVk0Am9l8MfTYQ8DgCfc9TGf7T3BlbkFJdIb1axbGPjzlJ9jaFI40mv2F_b9bZ9x_1LJ17tM2zrUt_yuhzel63HnVbG8Sy4rD3O3kwA',
  cognito: {
    userPoolId: 'eu-west-1_IJ9NsZtHN',
    userPoolWebClientId: '7t5jtgq0gi1c9348enr33399ng',
  },
  communicationService: VonageConferenceService,
};
