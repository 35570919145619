import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country } from '../models/Country';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataService {
  public countryList$: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);

  constructor(private httpClient: HttpClient) {
    this.getCountries().subscribe((res) => this.countryList$.next(res));
  }

  private getCountries(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/ReferenceData/GetCountry`);
  }
}
