import { HostListener, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../package.json';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrganizationUser } from '../models/OrganizationUser';
import { UserManagement } from '../models/User';
import { EncodingFormat } from '../models/EncodingFormat';
import { LiveCaseUsers, SharedUserWithRights } from '../models/MyLibraryContent';
import { AnimationOptions } from 'ngx-lottie';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public isMobile: boolean = false;
  public isTouchScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerHeight <= 600;
  }

  private availaibleSourceIconList: string[] = [
    'AngioIcon',
    'ComputerIcon',
    'ConferenceIcon',
    'EchoIcon',
    'EndoscopieIcon',
    'EnSiteIcon',
    'HaemodynamicsIcon',
    'IvusoctIcon',
    'RadioIcon',
    'SideCamIcon',
    'StentIcon',
    'TopCamIcon',
  ];

  options: AnimationOptions[] = [
    {
      path: '/assets/ciseaux.json',
    },
    {
      path: '/assets/coeur.json',
    },
    {
      path: '/assets/ensemble.json',
    },
  ];

  private passRequirement = {
    passwordMinLowerCase: 1,
    passwordMinNumber: 1,
    passwordMinSymbol: 1,
    passwordMinUpperCase: 1,
    passwordMinCharacters: 8,
  };

  public getAnimations() {
    return this.options[Math.floor(Math.random() * this.options.length)];
  }

  public passwordPattern = [
    `(?=([^a-z]*[a-z]){${this.passRequirement.passwordMinLowerCase},})`,
    `(?=([^A-Z]*[A-Z]){${this.passRequirement.passwordMinUpperCase},})`,
    `(?=([^0-9]*[0-9]){${this.passRequirement.passwordMinNumber},})`,
    `(?=(.*[\$\@\$\!\%\*\?\&\#\.\-]){${this.passRequirement.passwordMinSymbol},})`,
    `[A-Za-z\\d\$\@\$\!\%\*\?\&\#\.\-]{${this.passRequirement.passwordMinCharacters},}`,
  ]
    .map((item) => item.toString())
    .join('');

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirmPassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  };

  public genereateConferenceName(): string {
    let result = '';
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 5; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return `${result}`;
  }

  public getCurrentAppVersion(): string {
    return packageJson.version;
  }

  public getCurrentEnvironment(): string {
    return environment.env;
  }

  /**
   * Get user organization shortname depending if there is an account linked to this user
   * @param user
   */
  public getOrganizationUserShortName(user: OrganizationUser): string {
    return user.firstName === 'UNDEFINED' ? this.getShortName(user.userEmail) : this.getShortName(user.firstName + ' ' + user.lastName);
  }

  /**
   * Get user to manage shortname depending if there is an account linked to this user
   * @param user
   */
  public getUserToManageShortname(user: UserManagement): string {
    return user.firstName === 'UNDEFINED' || user.firstName === '' ? this.getShortName(user.email) : this.getShortName(user.firstName + ' ' + user.lastName);
  }

  /**
   * Get user to manage shortname depending if there is an account linked to this user
   * @param user
   */
  public getUserWithSharedMediaShortName(user: SharedUserWithRights): string {
    return user.firstName === 'UNDEFINED' || user.firstName === '' ? this.getShortName(user.email) : this.getShortName(user.firstName + ' ' + user.lastName);
  }

  /**
   *
   * @param liveCaseUser Get user in live case shortname
   */
  public getUserInLiveCaseShortName(liveCaseUser: LiveCaseUsers): string {
    return liveCaseUser.firstName === 'UNDEFINED' || liveCaseUser.firstName === ''
      ? this.getShortName(liveCaseUser.email)
      : this.getShortName(liveCaseUser.firstName + ' ' + liveCaseUser.lastName);
  }

  /**
   * Get all available encoding formats
   */
  public getAvailableEncodingFormats(): Observable<EncodingFormat[]> {
    return this.httpClient.get<EncodingFormat[]>(`${environment.apiUrl}/LiveCase/GetAllEncodingFormats`);
  }

  public getShortName(fullname: string): string {
    const name = fullname.trim().replace(/\s+/g, ' ');
    if (name?.length) {
      if (name.indexOf(' ') != -1) {
        let splitted = name.split(' ');
        if (splitted.length == 3) {
          return `${Array.from(splitted[1])[0]}${Array.from(splitted[splitted.length - 1])[0]}`;
        } else {
          return `${Array.from(splitted[0])[0]}${Array.from(splitted[splitted.length - 1])[0]}`;
        }
      } else {
        return Array.from(name)[0];
      }
    }
    return '';
  }
  /**
   * Get random color for a profile
   * @returns
   */
  public getRandomProfileColor(): string {
    return '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substring(0, 6).toUpperCase();
  }

  public sourceIconExists(sourceICon: string) {
    return this.availaibleSourceIconList.indexOf(sourceICon) != -1;
  }

  public getSourceColorIndex(index: number) {
    if (index <= 16) {
      return index;
    }
    return index % 16;
  }

  public registerIcons(): void {
    // sources icons
    this.matIconRegistry.addSvgIcon('AngioIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/AngioIcon.svg'));
    this.matIconRegistry.addSvgIcon('ComputerIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/ComputerIcon.svg'));
    this.matIconRegistry.addSvgIcon('ConferenceIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/ConferenceIcon.svg'));
    this.matIconRegistry.addSvgIcon('EchoIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/EchoIcon.svg'));
    this.matIconRegistry.addSvgIcon('EndoscopieIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/EndoscopieIcon.svg'));
    this.matIconRegistry.addSvgIcon('EnSiteIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/EnSiteIcon.svg'));
    this.matIconRegistry.addSvgIcon('HaemodynamicsIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/HaemodynamicsIcon.svg'));
    this.matIconRegistry.addSvgIcon('IvusoctIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/IvusoctIcon.svg'));
    this.matIconRegistry.addSvgIcon('RadioIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/RadioIcon.svg'));
    this.matIconRegistry.addSvgIcon('SideCamIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/SideCamIcon.svg'));
    this.matIconRegistry.addSvgIcon('StentIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/StentIcon.svg'));
    this.matIconRegistry.addSvgIcon('TopCamIcon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/TopCamIcon.svg'));

    this.matIconRegistry.addSvgIcon('add-participant', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/add_participant.svg'));
    this.matIconRegistry.addSvgIcon('audio-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/audio.svg'));
    this.matIconRegistry.addSvgIcon('body-tracking', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/body_tracking.svg'));
    this.matIconRegistry.addSvgIcon('chat-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/chat-icon_new.svg'));
    this.matIconRegistry.addSvgIcon('clear-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/clear.svg'));
    this.matIconRegistry.addSvgIcon('drag-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/drag-dot.svg'));
    this.matIconRegistry.addSvgIcon('draw-clear', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/draw-clear.svg'));
    this.matIconRegistry.addSvgIcon('draw-ellipse', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/draw-ellispe.svg'));
    this.matIconRegistry.addSvgIcon('draw-eraser', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/draw-eraser.svg'));
    this.matIconRegistry.addSvgIcon('draw-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/draw-icon_new.svg'));
    this.matIconRegistry.addSvgIcon('draw-pen', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/draw-pen.svg'));
    this.matIconRegistry.addSvgIcon('draw-rectangle', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/draw-rectangle.svg'));
    this.matIconRegistry.addSvgIcon('draw-undo', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/draw-undo.svg'));
    this.matIconRegistry.addSvgIcon('detect-screen', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/detect_screen.svg'));
    this.matIconRegistry.addSvgIcon('fullscreen_off', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/fullscreen_off.svg'));
    this.matIconRegistry.addSvgIcon('fullscreen_on', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/fullscreen_on.svg'));
    this.matIconRegistry.addSvgIcon('hang-down-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/phone.svg'));
    this.matIconRegistry.addSvgIcon('headset-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/headphone.svg'));
    this.matIconRegistry.addSvgIcon('headset-on-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/headphone_on.svg'));
    this.matIconRegistry.addSvgIcon('headset-off-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/headphone_off.svg'));
    this.matIconRegistry.addSvgIcon('information', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/information.svg'));
    this.matIconRegistry.addSvgIcon('laser-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/laser_new.svg'));
    this.matIconRegistry.addSvgIcon('layout-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/layout-icon_new.svg'));
    this.matIconRegistry.addSvgIcon('source-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/sources_management.svg'));
    this.matIconRegistry.addSvgIcon('leave-conference', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/leave-conference_new.svg'));
    this.matIconRegistry.addSvgIcon('lines-width', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/lines.svg'));
    this.matIconRegistry.addSvgIcon('locked-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/locked.svg'));
    this.matIconRegistry.addSvgIcon('medical-team-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/medical-team-icon.svg'));
    this.matIconRegistry.addSvgIcon('medinbox-languette-new', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icon_medinbox.svg'));
    this.matIconRegistry.addSvgIcon('menu-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/menu.svg'));

    this.matIconRegistry.addSvgIcon('mic-off', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/mic-off_new.svg'));
    this.matIconRegistry.addSvgIcon('mic-on', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/mic-on_new.svg'));
    this.matIconRegistry.addSvgIcon('minus-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/minus.svg'));
    this.matIconRegistry.addSvgIcon('offlive-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/off_live.svg'));
    this.matIconRegistry.addSvgIcon('onlive-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/on_live.svg'));

    this.matIconRegistry.addSvgIcon(
      'participant-list-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/participants-list-icon_new.svg'),
    );
    this.matIconRegistry.addSvgIcon('participant-toolbar-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/participants_toolbar.svg'));
    this.matIconRegistry.addSvgIcon('pause-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/pause-icon_new.svg'));
    this.matIconRegistry.addSvgIcon('plus-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/plus.svg'));
    this.matIconRegistry.addSvgIcon('preferences-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/preferences-icon.svg'));
    this.matIconRegistry.addSvgIcon('records', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/records.svg'));
    this.matIconRegistry.addSvgIcon('screenshare-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/screenshare-icon_new.svg'));
    this.matIconRegistry.addSvgIcon('send-chat', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/send_chat_message.svg'));
    this.matIconRegistry.addSvgIcon('speaking-surgeon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/speaking-surgeon.svg'));
    this.matIconRegistry.addSvgIcon('start-record-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/start-record-icon_new.svg'));
    this.matIconRegistry.addSvgIcon('stop-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/stop-icon_new.svg'));

    this.matIconRegistry.addSvgIcon('text-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/texte.svg'));
    this.matIconRegistry.addSvgIcon('text-plus-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/tplus.svg'));
    this.matIconRegistry.addSvgIcon('text-minus-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/tmoins.svg'));
    this.matIconRegistry.addSvgIcon('stop-record', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/stop_record.svg'));
    this.matIconRegistry.addSvgIcon('unlocked-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/unlocked.svg'));
    this.matIconRegistry.addSvgIcon('whiteboard-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/whiteboard-icon_new.svg'));
    this.matIconRegistry.addSvgIcon('home-drawing', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/home.svg'));
    this.matIconRegistry.addSvgIcon('heart-drawing', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/heart.svg'));
    this.matIconRegistry.addSvgIcon('scissors-drawing', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/scissors.svg'));
    this.matIconRegistry.addSvgIcon('mail-drawing', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/mail.svg'));
    this.matIconRegistry.addSvgIcon('step-drawing', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/step.svg'));
    this.matIconRegistry.addSvgIcon('profile-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/profile.svg'));
    this.matIconRegistry.addSvgIcon('log-out-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/leave-conference.svg'));
    this.matIconRegistry.addSvgIcon('horloge', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/horloge.svg'));
    this.matIconRegistry.addSvgIcon('chrono', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/chrono.svg'));
    this.matIconRegistry.addSvgIcon('add-mask', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/masques.svg'));
    this.matIconRegistry.addSvgIcon('delete', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/supprimer.svg'));
    this.matIconRegistry.addSvgIcon('fav', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/fav.svg'));
    this.matIconRegistry.addSvgIcon('fav-disabled', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/fav-disabled.svg'));
    this.matIconRegistry.addSvgIcon('telecharger', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/telecharger.svg'));
    this.matIconRegistry.addSvgIcon('see-more', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/see-more.svg'));
    this.matIconRegistry.addSvgIcon('bookmark_blue', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/bookmark_blue.svg'));
    this.matIconRegistry.addSvgIcon('bookmark_grey', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/bookmark_grey.svg'));
    this.matIconRegistry.addSvgIcon('bookmark_side_nav', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/bookmark_side_nav.svg'));
    this.matIconRegistry.addSvgIcon('ia-medinbox-logo', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/ia-medinbox-logo.svg'));

    this.matIconRegistry.addSvgIcon('cancel', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/annuler.svg'));
    this.matIconRegistry.addSvgIcon('glass', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/loupe.svg'));
    this.matIconRegistry.addSvgIcon('glass-black', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/loupe-black.svg'));

    this.matIconRegistry.addSvgIcon('share', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/partager.svg'));
    this.matIconRegistry.addSvgIcon('my-organizations', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/structures.svg'));
    this.matIconRegistry.addSvgIcon('my-records', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/enregistrements.svg'));
    this.matIconRegistry.addSvgIcon('paramters', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/parametres.svg'));
    this.matIconRegistry.addSvgIcon('my-equipment', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/equipements.svg'));
    this.matIconRegistry.addSvgIcon('contact', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/contact.svg'));
    this.matIconRegistry.addSvgIcon('profile', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/profil.svg'));

    this.matIconRegistry.addSvgIcon('tuto', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/tuto.svg'));

    // Icons used for my library
    this.matIconRegistry.addSvgIcon('cancel-video-edition', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/cancel-video-edition.svg'));
    this.matIconRegistry.addSvgIcon('edit-video', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/edit-video.svg'));
    this.matIconRegistry.addSvgIcon('share-file', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/share-file.svg'));
    this.matIconRegistry.addSvgIcon('delete-file', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/delete-file.svg'));
    this.matIconRegistry.addSvgIcon('synchronization', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/synchronization.svg'));
    this.matIconRegistry.addSvgIcon('local-synchronization', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/local-synchronization.svg'));
    this.matIconRegistry.addSvgIcon('cloud-synchronization', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/cloud-synchronization.svg'));
    this.matIconRegistry.addSvgIcon('synchronized', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/synchronized.svg'));
    this.matIconRegistry.addSvgIcon('not-synchronized', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/not-synchronized.svg'));
    this.matIconRegistry.addSvgIcon('media-not-available', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/media-not-available.svg'));
    this.matIconRegistry.addSvgIcon('remove-item', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/remove-item.svg'));
    this.matIconRegistry.addSvgIcon('sync-in-progress', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/sync-in-progress.svg'));
    this.matIconRegistry.addSvgIcon('sync-partially', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/sync-partially.svg'));
    this.matIconRegistry.addSvgIcon('download-file', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/download-file.svg'));
  }

  //https://webrtc.github.io/samples/src/content/devices/input-output/
  //https://github.com/webrtc/samples/blob/gh-pages/src/content/devices/input-output/js/main.js

  public getMedinboxLinkVideoDeviceId(mediaDevices: MediaDeviceInfo[]): string | null {
    mediaDevices = mediaDevices.filter((d) => d.kind == 'videoinput');
    const medinboxIndex = mediaDevices.findIndex((d) => d.label == 'MedinboxLink');
    return medinboxIndex != -1 ? mediaDevices[medinboxIndex].deviceId : mediaDevices.length ? mediaDevices[0].deviceId : null;
  }

  public getMedinboxLinkAudioDeviceId(inputDevices: MediaDeviceInfo[]): string | null {
    inputDevices = inputDevices.filter((d) => d.kind == 'audioinput');
    const medinboxIndex = inputDevices.findIndex((d) => d.deviceId == 'communications');
    return medinboxIndex != -1 ? inputDevices[medinboxIndex].deviceId : inputDevices.length ? inputDevices[0].deviceId : null;
  }

  public goBack(): void {
    if (this.activatedRoute.snapshot.queryParams.returnUrl) {
      let encodedUrl = encodeURI(this.activatedRoute.snapshot.queryParams.returnUrl);
      this.router.navigateByUrl(encodedUrl);
    } else {
      this.router.navigate(['/']);
    }
  }

  /**
   * Convert seconds to minutes
   * @param seconds
   * @returns
   */
  // I want to change this function so it returns 47s instead of 0min 47s
  public secondsToMinutes(seconds: number | undefined): string {
    if (seconds !== undefined) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      if (minutes > 0) {
        return `${minutes}min ${remainingSeconds}s`;
      } else {
        return `${remainingSeconds}s`;
      }
    }
    return '0s';
  }

  public numToTime(seconds: number | undefined): string {
    if (seconds != undefined) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      const pad = (num: number): string => num.toString().padStart(2, '0');

      return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
    }
    return '00:00:00';
  }
}
