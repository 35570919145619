import { Injectable, signal } from '@angular/core';
import { Bookmark } from '../models/Bookmark';
import { WebSocketClientService } from './web-socket-client.service';
import { WebSocketActionEnum } from '../models/enums/WebsocketActions';
import { MedinboxService } from './medinbox.service';
import { UserService } from './user.service';
import { LiveService } from './live.service';
import { WebSocketMessage } from '../models/WebSocketMessage';

@Injectable({
  providedIn: 'root',
})
export class BookmarkService {
  public bookmarks$ = signal<Bookmark[]>([]);
  public bookmarkTime$ = signal<number>(0);
  public showBookmarkForm$ = signal<boolean>(false);

  constructor(
    private webSocketClient: WebSocketClientService,
    private medinboxService: MedinboxService,
    private userService: UserService,
    private liveService: LiveService,
  ) {
    this.webSocketClient.medinboxMessage$.subscribe((message) => {
      if (message && message.audience == 'EQUIPMENT' && message.sourceId == this.medinboxService.medinboxId$.getValue()) {
        if (message.message.action == WebSocketActionEnum.GET_BOOKMARKS) {
          this.bookmarks$.set(message.message.params);
        }
      }
    });
  }

  public getBookmarks() {
    this.webSocketClient
      .sendMedinboxMessage({
        audience: 'EQUIPMENT',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.medinboxService.medinboxId$.getValue(),
        message: {
          action: WebSocketActionEnum.GET_BOOKMARKS,
        },
        liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
      } as WebSocketMessage)
      .catch((err) => {
        console.log(err);
      });
  }

  public createBookmark(bookmark: Bookmark) {
    this.webSocketClient
      .sendMedinboxMessage({
        audience: 'EQUIPMENT',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.medinboxService.medinboxId$.getValue(),
        message: {
          action: WebSocketActionEnum.ADD_BOOKMARK,
          params: bookmark,
        },
        liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
      } as WebSocketMessage)
      .catch((err) => {
        console.log(err);
      });
  }

  public deleteBookmark(bookmark: Bookmark) {
    this.webSocketClient
      .sendMedinboxMessage({
        audience: 'EQUIPMENT',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.medinboxService.medinboxId$.getValue(),
        message: {
          action: WebSocketActionEnum.DELETE_BOOKMARK,
          params: bookmark,
        },
        liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
      } as WebSocketMessage)
      .catch((err) => {
        console.log(err);
      });
  }

  public updateBookmark(bookmark: Bookmark) {
    this.webSocketClient
      .sendMedinboxMessage({
        audience: 'EQUIPMENT',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.medinboxService.medinboxId$.getValue(),
        message: {
          action: WebSocketActionEnum.EDIT_BOOKMARK,
          params: bookmark,
        },
        liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
      } as WebSocketMessage)
      .catch((err) => {
        console.log(err);
      });
  }
}
