<span class="dialog-tile">{{ 'ORGANIZATIONS.MANAGE_USERS' | translate }}</span>

<div class="flex flex-col overflow-y-auto user-container pr-4">
  @if (data.users) {
    @if (data.users) {
      <mat-selection-list (selectionChange)="userSelected($event.options)">
        @for (user of data.users; track user) {
          <div class="flex items-center my-2 w-full user-list">
            <mat-list-option [value]="user" [selected]="isSelected(user)">
              <div class="flex-none grid grid-flow-col auto-cols-max mx-auto py-4 gap-2">
                <div class="noselect organization-user bg-yellow-400 mt-2" [ngStyle]="{backgroundColor: user.profileColor}">
                  {{ utils.getUserToManageShortname(user) }}
                </div>
                <div class="flex-col">
                  <div>
                    @if (user.firstName !== 'UNDEFINED') {
                      <p class="user-name">{{ user.firstName }} {{ user.lastName }}</p>
                    }
                  </div>
                  <div>
                    <p class="user-email">{{ user.email }}</p>
                  </div>
                </div>
              </div>
            </mat-list-option>
          </div>
        }
      </mat-selection-list>
    } @else {
      <div class="text-center">
        {{ 'ORGANIZATIONS.NO_USER_FOUND' | translate }}
      </div>
    }
  }
</div>
<div class="justify-start w-full mt-6">
  {{ selectedUsers.length }} {{ 'ORGANIZATIONS.USER_SELECTED' | translate }}
</div>

<div class="flex w-full justify-between pt-4">
  <button mat-raised-button cdkFocusInitial class="button-cancel" (click)="cancelButton()">{{ 'GLOBAL.CANCEL' | translate }}</button>
  <button mat-raised-button [disabled]="this.selectedUsers.length == 0" (click)="removeUsers()" cdkFocusInitial class="button-remove ml-4">
    <mat-icon>delete</mat-icon>
    {{ 'GLOBAL.REMOVE' | translate }}
  </button>
</div>