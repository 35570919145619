export interface LiveState {
  liveReference: string;
  isRecording: boolean;
  currentStates: State[];
  statesHistory: [State[]];
}

export interface State {
  timeStamp?: number;
  stateType: StateEmum;
  user: string;
  params?: any;
}

export enum StateEmum {
  PAUSE = 'PAUSE',
  WHITEBOARD = 'WHITEBOARD',
  DRAWING = 'DRAWING',
  RECORD = 'RECORD',
  SEND_VIDEO = 'SEND_VIDEO',
  SHARE_SCREEN = 'SHARE_SCREEN',
  LASER = 'LASER',
  PRIVATE_TALK = 'PRIVATE_TALK',
}
