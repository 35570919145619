<div class="h-full w-full flex flex-col">
  <div class="flex items-center justify-center mt-4">
    <div class="medinbox-logo" [routerLink]="''">
      <img src="assets/medinbox-logo.png" alt="medinbox logo" />
    </div>
  </div>
  <div class="flex flex-col justify-center items-center w-full h-full p-8 overflow-y-auto">
    <div class="flex flex-col w-full max-w-md h-full">
      <button class="self-start" (click)="this.utils.goBack()">
        <div class="back">&lt;&nbsp;{{ 'GLOBAL.BACK' | translate }}</div>
      </button>

      <div class="grid">
        <label class="page-title">
          {{ 'SHARED.BEFORE_SESSION.CREATE_LIVE' | translate }}
        </label>
        <label class="description mt-4">
          {{ 'SHARED.BEFORE_SESSION.BEFORE_LAUNCHING' | translate }}
        </label>
      </div>

      <form [formGroup]="form" class="mt-2 mb-2">
        <div class="label mb-1">{{ 'SHARED.BEFORE_SESSION.ENTER_LIVE_NAME' | translate }}</div>
        <p class="criteria mt-1 mb-1">
          {{ 'SHARED.BEFORE_SESSION.TRACK_EASILY' | translate }}
        </p>
        <div class="flex relative">
          <input
            matInput
            #conferenceAliasInput
            formControlName="liveName"
            type="text"
            [className]="errors.liveName ? 'input-error input-position' : 'input-position'"
            required
            placeholder="{{ 'GLOBAL.LIVE_NAME' | translate }}"
            minlength="4"
            maxlength="40"
            (focus)="handleFocus()"
            (focusout)="handleConfName()"
          />
          @if (errors.liveName) {
            <mat-error class="error-input-outside-box">{{ errors.liveName }}</mat-error>
          }
        </div>
        <div class="label mt-2 mb-1">{{ 'SHARED.BEFORE_SESSION.MODERATOR' | translate }}</div>
        <p class="criteria mt-1 mb-1">
          {{ 'SHARED.BEFORE_SESSION.OPTIONNAL' | translate }}
        </p>
        <div class="relative">
          <input
            #moderatorInput
            formControlName="cohostEmail"
            [className]="errors.cohostEmail ? 'input-error input-position pr-[50%]' : 'input-position pr-[35px]'"
            matInput
            (keyup.enter)="addModerator(moderatorInput.value); moderatorInput.value = ''"
            placeholder="{{ 'SHARED.BEFORE_SESSION.MODERATOR' | translate }}"
          />
          <button
            class="material-icons add"
            [disabled]="this.form.controls.cohostEmail.invalid || !moderatorInput.value"
            (click)="addModerator(moderatorInput.value); moderatorInput.value = ''"
          >
            add_circle
          </button>
          @if (this.form.get('cohostEmail')?.dirty && errors.cohostEmail) {
            <mat-error class="error-input-outside-box with-button">{{ 'SHARED.ERRORS.INVALID_EMAIL' | translate }}</mat-error>
          }
        </div>
        <mat-chip-listbox class="my-1">
          @for (invite of moderatorInvites; track invite) {
            <mat-chip class="chip" (removed)="remove(invite, moderatorInvites)">
              <span class="chip-text">{{ invite }}</span>
              <button class="chip-button" matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          }
        </mat-chip-listbox>

        @if (isDetails) {
          <div>
            <div class="label mt-2 mb-1">{{ 'SHARED.BEFORE_SESSION.OWNER' | translate }}</div>
            <!-- OWNER -->
            <div class="relative">
              <input
                type="text"
                formControlName="owner"
                class="input-position"
                matInput
                placeholder="{{ 'SHARED.BEFORE_SESSION.OWNER' | translate }}"
                [className]="errors.owner ? 'input-error input-position' : 'input-position'"
              />
              @if (this.form.get('owner')?.dirty && errors.owner) {
                <mat-error class="error-input-outside-box-margin">{{ 'SHARED.ERRORS.INVALID_EMAIL' | translate }}</mat-error>
              }
            </div>
            <div class="label mt-2 mb-1">{{ 'SHARED.BEFORE_SESSION.URL' | translate }}</div>
            <div class="flex relative">
              <input
                type="text"
                class="input-position"
                [readonly]="true"
                matInput
                [value]="sessionUrl"
                placeholder="{{ 'SHARED.BEFORE_SESSION.URL' | translate }}"
              />
            </div>
            <div class="label mt-2 mb-1">{{ 'GLOBAL.DESCRIPTION' | translate }}</div>
            <!-- DESCRIPTION -->
            <div class="flex relative">
              <textarea
                type="text"
                formControlName="description"
                class="input-position"
                matInput
                placeholder="{{ 'GLOBAL.DESCRIPTION' | translate }}"
                maxlength="240"
              ></textarea>
            </div>
            <div class="label mt-2 mb-1">{{ 'SHARED.BEFORE_SESSION.CONTACT' | translate }}</div>
            <!-- INVITE -->
            <div class="relative">
              <input
                #inviteInput
                formControlName="contact"
                class="input-position"
                [className]="errors.contact ? 'input-error input-position pr-[50%]' : 'input-position pr-[35px]'"
                matInput
                (keyup.enter)="addContact(inviteInput.value); inviteInput.value = ''"
                placeholder="{{ 'SHARED.BEFORE_SESSION.CONTACT' | translate }}"
              />
              <button
                class="material-icons add"
                [disabled]="this.form.controls.contact.invalid || !inviteInput.value"
                (click)="addContact(inviteInput.value); inviteInput.value = ''"
              >
                add_circle
              </button>
              @if (this.form.get('contact')?.dirty && errors.contact) {
                <mat-error class="error-input-outside-box-margin">{{ 'SHARED.ERRORS.INVALID_EMAIL' | translate }}</mat-error>
              }
            </div>
            <mat-chip-listbox class="my-1">
              @for (invite of contactInvites; track invite) {
                <mat-chip class="chip" (removed)="remove(invite, contactInvites)">
                  <span class="chip-text">{{ invite }}</span>
                  <button class="chip-button" matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
              }
            </mat-chip-listbox>
          </div>
        }
      </form>

      @if (!isDetails) {
        <div class="flex justify-center mt-2">
          <button mat-raised-button class="button button-details !w-full" (click)="this.isDetails = true">
            {{ 'SHARED.BEFORE_SESSION.ADD_DETAILS' | translate }}
          </button>
        </div>
      }

      <div class="flex justify-center mt-2">
        <button mat-raised-button class="button-ok !w-full" [disabled]="form.invalid" (click)="createALive()">
          {{ 'SHARED.BEFORE_SESSION.START_LIVE' | translate }}
        </button>
      </div>
    </div>
  </div>
  <app-terms-and-conditions class="conditions" [callback]="this.form.value"></app-terms-and-conditions>
</div>
