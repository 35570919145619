@if (users) {
  <div class="flex-none grid grid-flow-col auto-cols-max mx-auto py-4 gap-2">
    @for (user of users | slice:0:usersToDisplay; track user) {
      <div class="noselect organization-user bg-yellow-400" [ngStyle]="{backgroundColor: user.profileColor}"
        matTooltip="{{ (user.firstName === 'UNDEFINED') ? user.userEmail : user.firstName + ' ' + user.lastName }}">
        {{ utils.getOrganizationUserShortName(user) }}
      </div>
    }
    @if (users.length > usersToDisplay) {
      <div class="noselect other-user">
        +{{ users.length - usersToDisplay }}
      </div>
    }
  </div>
}

