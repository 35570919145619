@if (
  {
    exclusiveHeadSetId: audioService.currentExclusiveSpeakerHeadsetObservable() | async,
    currentRights: liveService.currentrights$ | async,
    show: audioService.showHeadSetNotificationBar$ | async
  };
  as values
) {
  @if (values.exclusiveHeadSetId != null && values.show) {
    @if (getHeadSet(values.exclusiveHeadSetId) | async; as headset) {
      @if (headset != null) {
        <div class="flex flex-col notification-container" [ngClass]="(sideNavService.sideNavOpen$ | async) ? 'container-move' : ''">
          <div class="flex justify-between w-full px-4 py-2">
            <span class="notification-title">Exclusive Speaker</span>
            <button class="w-[26px] h-[26px] ml-2" (click)="dismissPopUp()">
              <i class="material-icons" style="color: var(--bg-notification-decline)">close</i>
            </button>
          </div>
          <div class="flex w-full px-2 pb-2">
            <div class="flex min-h-[30px] justify-between headset-element">
              <div class="flex items-center">
                <div class="headset-circle ml-2" [ngStyle]="{ 'background-color': headset.Color }">
                  <span class="headset-circle-name">{{ headset.ShortName }}</span>
                </div>
                <span class="headset-name">{{ headset.Name }}</span>
              </div>
              @if (values.currentRights?.canControlHeadset) {
                <button class="button stop-private-talk" (click)="stopExclusiveDiscussion(headset.HeadsetId)">Stop Exclusive Talk</button>
              }
            </div>
          </div>
        </div>
      }
    }
  }
}
