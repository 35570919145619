@if (this.agree()) {
  <div class="text w-full break-words">
    {{ 'SHARED.TERMS_AND_CONDITIONS.READ_AND_AGREE' | translate }}
    <a class="link" href="javascript:void(0)" (click)="openLegal()">
      {{ gdpr() ? ('SHARED.TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS_GDPR' | translate) : ('SHARED.TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS' | translate) }}
    </a>
  </div>
} @else {
  <div class="mt-4">
    <a (click)="openLegal()">
      <span>{{ 'GLOBAL.COPYRIGHT' | translate }}</span>
      @if (!lineBreak()) {
        <span> - </span>
      } @else {
        <span> <br /> </span>
      }
      <span class="underline">{{ 'GLOBAL.LEGAL' | translate }}</span>
    </a>
  </div>
}
