<div class="flex w-fit items-center">
  <button class="button-exit flex items-center justify-center rounded-full p-1" (click)="dialogRef.close()">
    <i class="material-icons">close</i>
  </button>
  <div class="flex flex-col pl-4">
    <div class="flex justify-between items-center">
      <span class="error-message pl-2" [innerHTML]="data.text"> </span>
    </div>
    <div class="flex justify-center pt-4">
      <button mat-button class="button-ok" (click)="dialogRef.close()"><span class="button-ok-text">OK</span></button>
    </div>
  </div>
</div>
